export const ROLES = {
  STANDARD: 'standard',
  ADMIN: 'administrator',
  READ_ONLY: 'read-only',
  SUPER_USER: 'superuser',
}

export const ROLE_DISPLAY_NAMES = {
  [ROLES.STANDARD]: 'Standard User',
  [ROLES.ADMIN]: 'Administrator',
  [ROLES.READ_ONLY]: 'Read-Only User',
  [ROLES.SUPER_USER]: 'SUPERUSER',
}
