import React from 'react'
import { useLocation } from 'react-router-dom'
import crypto from 'crypto'
import _kebabCase from 'lodash/kebabCase'

/**
 * encrypt `data` buffer or string into a sha1 hash
 * @name sha1
 * @param {string|Buffer}
 * @param {string} encoding
 * @returns {string} sha1 hash
 * @example
 *  sha1('foo bar') // => '3773dea65156909838fa6c22825cafe090ff8030'
 */
export const sha1 = (data, encoding) =>
  crypto
    .createHash('sha1')
    .update(data)
    .digest(encoding || 'hex')

export const prettyToFixed = (num, precision = 2) => {
  if (num >= 1) {
    return parseFloat(Number(num).toFixed(precision))
  }
  return parseFloat(
    Number(num)
      .toFixed(20)
      .match(new RegExp(`^-?\\d*\\.?0*\\d{0,${precision}}`))[0]
  )
}

export const numberFromPercentString = (str) => {
  return Number(str?.split('%')?.[0])
}

export const getLastArrayItem = (arr) => {
  if (arr?.length && Array.isArray(arr)) {
    return arr[arr.length - 1]
  }
  return null
}

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

export const getRandomItem = (array) =>
  array[Math.floor(Math.random() * array.length)]

export const getSeededRandomItem = (array, rng) =>
  array[Math.floor(rng() * array.length)]

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max)
}

export const shuffleArray = (array, rng) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(rng() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}

export const getFirstInitial = (name) => {
  const splitName = name.split(' ')
  return `${splitName[0].slice(0, 1)}.`
}

export const getLastName = (name) => {
  const splitName = name.split(' ')
  return splitName[splitName.length - 1]
}

// URLSearchParams parses "+" characters, which can be a problem for some emails
export class UrlParams {
  constructor(search) {
    this.qs = (search || location.search).substr(1)
    this.params = {}
    this.parseQuerstring()
  }
  parseQuerstring() {
    this.qs.split('&').reduce((a, b) => {
      let [key, val] = b.split('=')
      a[key] = val
      return a
    }, this.params)
  }
  get(key) {
    return this.params[key]
  }
}

export const handleStringifiedResponse = (res) => {
  return JSON.parse(res.data)
}

export const isDevEnv = () => process.env.NODE_ENV === 'development'

export const reachApiFetchUrl = (url) => {
  if (url.charAt('0') === '/' && !isDevEnv()) {
    // this mapping should match setupProxy.js
    return `https://app.reach.security${url}`
  }
  return url
}

export const constructPersonaLink = (groupName) =>
  `/personas/${_kebabCase(groupName)}`

const OPERATING_SYSTEMS = {
  MAC_OS: 'MacOS',
  WINDOWS: 'Windows',
  UNIX: 'Unix',
  LINUX: 'Linux',
}
export const getOperatingSystem = () => {
  let osName = undefined
  if (navigator.userAgent.indexOf('Win') !== -1)
    osName = OPERATING_SYSTEMS.WINDOWS
  if (navigator.userAgent.indexOf('Mac') !== -1)
    osName = OPERATING_SYSTEMS.MAC_OS
  if (navigator.userAgent.indexOf('X11') !== -1) osName = OPERATING_SYSTEMS.UNIX
  if (navigator.userAgent.indexOf('Linux') !== -1)
    osName = OPERATING_SYSTEMS.LINUX

  return osName
}

export const isMacOs = () => {
  return getOperatingSystem() === OPERATING_SYSTEMS.MAC_OS
}

export const lerp = (start, end, amt) => {
  return (1 - amt) * start + amt * end
}

export const useQueryParams = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const getNumberFromPercentString = (str) => {
  if (str === null || str === undefined) return 0
  return Number(str?.split?.('%')[0])
}
