import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Box,
  Center,
  Flex,
  Icon,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { useDropzone } from 'react-dropzone'
import {
  postConnectionCredentials,
  postSnapshotGenerate,
  postUploadSnapshot,
  //   postSnapshotConfirm,
} from 'context/App/api'
import { useMutation } from 'react-query'
import { prettyToFixed } from 'utils'
import { BsDownload } from 'react-icons/bs'
import { useApp } from 'context/App'
import { CONNECTION_METHODS } from '../../constants'

const FileSnapshotForm = ({
  setSubmitButton,
  application,
  product,
  onSuccess,
}) => {
  const [renderKey, setRenderKey] = useState(Math.random())
  const [s3Data, setS3Data] = useState(null)

  const { handleError } = useApp()

  const { isLoading: isLoadingS3Data, mutate: postGenerateSnapshotUrl } =
    useMutation(postSnapshotGenerate, {
      onSuccess: (res) => setS3Data(res?.data),
    })

  const { isLoading: isGeneratingApp, mutate: generateActiveDirectoryApp } =
    useMutation(() =>
      postConnectionCredentials({
        product: product,
        authMethod: CONNECTION_METHODS.DIRECT_UPLOAD,
        credentials: {},
      })
    )

  const { isLoading: isUploading, mutate: postFileToS3 } = useMutation(
    postUploadSnapshot,
    {
      onSuccess: onSuccess,
      onError: handleError,
    }
  )

  useEffect(() => {
    // generate a local_ad app if one did one exist before
    if (!application?.uuid) {
      generateActiveDirectoryApp()
    } else {
      postGenerateSnapshotUrl(application?.uuid)
    }
  }, [application])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.ldif,application/zip',
    maxFiles: 1,
  })

  useEffect(() => {
    setSubmitButton(
      <Button
        key={`snapshot-button-${acceptedFiles.length}-${
          isLoadingS3Data ? 'l' : ''
        }-${isUploading ? 'l' : ''}`}
        mr="8px"
        isLoading={isGeneratingApp || isLoadingS3Data || isUploading}
        loadingText={isLoadingS3Data ? 'Initializing' : 'Uploading'}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (acceptedFiles.length > 0) {
            postFileToS3()
          }
        }}
      >
        Upload Snapshot
      </Button>
    )
    return () => setSubmitButton(null)
  }, [
    acceptedFiles.length === 0,
    isLoadingS3Data,
    isUploading,
    isGeneratingApp,
    application,
    s3Data,
  ])

  const file = acceptedFiles[0]

  const linkColor = mode('reachBlue.500', 'reachBlue.200')

  const handleRemoveFile = (e) => {
    e.preventDefault()
    e.stopPropagation()
    acceptedFiles.pop()
    setRenderKey(Math.random())
  }

  return (
    <Box key={renderKey} my="16px" opacity={isLoadingS3Data ? 0.6 : 1}>
      <section className="container">
        <Box
          cursor={'pointer'}
          p="48px"
          borderRadius="6px"
          border="1px dashed"
          opacity={1}
          borderColor="reachBlue.400"
          bg={file ? '#46CEB022' : 'transparent'}
          {...getRootProps({ className: 'dropzone' })}
        >
          <input {...getInputProps()} />
          <Center height="100%">
            <Flex flexDir="column" alignItems="center">
              <Icon
                color={linkColor}
                display="block"
                as={BsDownload}
                w="40px"
                h="40px"
                mb="8px"
              />
              {file ? (
                <Box>
                  <Flex fontWeight="bold" textAlign="center">
                    {file.path}
                    <Button
                      stopPropagation
                      preventDefault
                      colorScheme="red"
                      ml="8px"
                      size="xs"
                      variant="outline"
                      onClick={handleRemoveFile}
                    >
                      X
                    </Button>
                  </Flex>
                  <Box textAlign="center">
                    {file.size / 1000000 > 1
                      ? `${prettyToFixed(file.size / 1000000, 2)} mb`
                      : `${prettyToFixed(file.size / 1000, 2)} kb`}
                  </Box>
                </Box>
              ) : (
                <>
                  <Text opacity=".8" fontSize="14px" fontWeight="light">
                    Drag and Drop or click to find file
                  </Text>
                  <Text opacity=".5" fontSize="14px" fontWeight="light">
                    .zip or .ldif file types only
                  </Text>
                </>
              )}
            </Flex>
          </Center>
        </Box>
      </section>
    </Box>
  )
}

FileSnapshotForm.propTypes = {
  setSubmitButton: PropTypes.func,
  authMethod: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSuccess: PropTypes.func,
  application: PropTypes.object,
  product: PropTypes.string,
  onError: PropTypes.func,
}

export default FileSnapshotForm
