import axios from 'axios'
import { reachApiFetchUrl, sha1 } from 'utils'
import wait from 'waait'

export const postLogin = (body) =>
  axios.post('/api/auth', body, {
    withCredentials: true,
    crossDomain: true,
    headers: {
      'access-control-allow-credentials': true,
      'access-control-allow-origin': 'https://app.reach.security',
    },
  })

export const postLogout = (accessToken) =>
  axios
    .post(
      `/api/logout`,
      {
        access: accessToken,
      },
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    )
    .catch((e) => Promise.reject(e))

export const initiateResetPassword = (email) => {
  const params = new URLSearchParams([['email', email]])
  return axios
    .get('/api/password/reset', { params })
    .catch((e) => Promise.reject(e))
}

export const postResetPassword = ({ newPassword, totpToken, token }) =>
  axios
    .post('/api/password/reset', {
      new_password: newPassword,
      totp_token: totpToken,
      token: token,
    })
    .catch((e) => Promise.reject(e))

export const postUserRegister = ({ email, name, role }) =>
  axios
    .post('/api/customer/user/register', {
      email,
      name,
      role,
    })
    .catch((e) => Promise.reject(e))

export const getTenantList = () =>
  axios.get('/api/tenant/list').catch((e) => Promise.reject(e))

export const postTenantSwitch = (uuid) =>
  axios
    .post('/api/tenant/switch', {
      uuid,
    })
    .catch((e) => Promise.reject(e))

export const postRegisterCrossTenantUser = ({ email, role }) =>
  axios
    .post('/api/cross-tenant/register', {
      role,
      email,
    })
    .catch((e) => Promise.reject(e))

export const haveIBeenPwnedPassword = (password) => {
  return new Promise((resolve, reject) => {
    const async = async () => {
      try {
        const hash = sha1(password).toUpperCase()
        const prefix = hash.substr(0, 5)
        const suffix = hash.substr(5)

        const response = await axios
          .get(`https://api.pwnedpasswords.com/range/${prefix}`, {
            withCredentials: false,
          })
          .catch((e) => Promise.reject(e))

        const pwnedEntry = response?.data.split('\n').find((entry) => {
          const [suff] = entry.split(':')
          return suff === suffix
        })

        const pwnedTimes = pwnedEntry ? pwnedEntry.split(':')[1] : 0
        resolve(pwnedTimes)
      } catch (e) {
        reject(e)
      }
    }
    async()
  })
}

export const deleteSsoConfiguration = () =>
  axios
    .delete('/api/v1/auth/configuration/sso/configurations?status=enabled')
    .catch((e) => Promise.reject(e))

// using fetch instead of axios because referrer-policy needs
// to be set to be usable from stage.reach.security
// axios seems to be unable to do this
export const postSsoDetails = ({ values: body, accessToken }) =>
  fetch(reachApiFetchUrl('/api/v1/auth/configuration/sso/configurations'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    credentials: 'include',
    body: JSON.stringify(body),
    referrerPolicy: 'unsafe-url',
  })
    .then((response) => response.json())
    .catch((e) => Promise.reject(e))

export const getSsoConfigurations = () =>
  axios
    .get('/api/v1/auth/configuration/sso/configurations?status=enabled')
    .catch((e) => Promise.reject(e))

export const getSsoConfiguration = () =>
  new Promise((resolve) => {
    const asyncFn = async () => {
      await wait(1000)
      resolve({
        ssoUrl:
          'https://www.someurl.com/saml2_auth/2039r-0f02-0j92j-390jv-0232-3j90f23f',
        spEntityId:
          'https://www.someurl.com/content/plugins/saml-single-sign-on/',
      })
    }
    asyncFn()
  })

// using fetch instead of axios because referrer-policy needs
// to be set to be usable from stage.reach.security
// axios seems to be unable to do this
export const getSsoUser = ({ email, isRegistered = true }) =>
  fetch(
    reachApiFetchUrl(
      `/api/v1/auth/sso/user?email=${encodeURIComponent(
        email
      )}&registered_user=${encodeURIComponent(isRegistered)}`
    ),
    {
      referrerPolicy: 'unsafe-url',
    }
  )
    .then((response) => response.json())
    .catch((e) => Promise.reject(e))
