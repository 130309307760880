import React from 'react'
import PropTypes from 'prop-types'
import {
  Popover,
  PopoverTrigger,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  ButtonGroup,
  Button,
  Text,
  PopoverCloseButton,
} from '@chakra-ui/react'

const SSOSetupConfirmation = ({ children }) => {
  const initialFocusRef = React.useRef()

  return (
    <Popover initialFocusRef={initialFocusRef} placement="bottom">
      {({ onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent>
            <PopoverHeader pt={4} fontWeight="bold" border="0">
              Enable SSO
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Text>Are you sure you want to enable SSO?</Text>
              <Text>
                This action will be applied to your entire organization.
              </Text>
            </PopoverBody>
            <PopoverFooter
              border="0"
              d="flex"
              alignItems="center"
              justifyContent="flex-end"
              pb={4}
            >
              <ButtonGroup size="sm">
                <Button
                  onClick={() => {
                    // onEnableSSO()
                    onClose()
                  }}
                  type="submit"
                >
                  Make it so
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

SSOSetupConfirmation.propTypes = {
  children: PropTypes.any,
  onEnableSSO: PropTypes.func,
}

export default SSOSetupConfirmation
