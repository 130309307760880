import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Flex,
  Center,
  Icon,
  Heading,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { AiOutlineWarning } from 'react-icons/ai'
import Card from 'components/Shared/Card'
import { useAuth } from 'context/Auth'
import wait from 'waait'

const ErrorComponent = ({ error }) => {
  const { logout } = useAuth()

  useEffect(async () => {
    /* edge case - AuthenticatedApp cloudfront signed cookie expired and out of sync with access token */
    /* Loading chunk 2 failed */
    if (error?.message.includes?.('Loading chunk')) {
      await logout()
      // adding a short wait here before the reload to ensure any async js finishes
      await wait(10)
      window.location.reload(true)
    }
  }, [error])

  return (
    <Flex
      bg={mode('gray.100', 'gray.800')}
      alignItems="center"
      justifyContent="center"
      flexDir="column"
      h="100vh"
      w="100%"
    >
      <Center mb="32px">
        <Icon
          w="80px"
          h="80px"
          color={mode('gray.200', 'gray.600')}
          as={AiOutlineWarning}
        />
      </Center>
      <Card p="48px" mb="96px" maxW="480px">
        <Heading color={mode('reachBlue.400', 'reachBlue.300')} mb="8px">
          Egads!
        </Heading>

        <Heading
          color={mode('reachBlue.400', 'reachBlue.300')}
          size="md"
          mb="32px"
        >
          Something&apos;s gone wrong.
        </Heading>
        <Text size="lg" mb="8px">
          Please try refreshing the page, or contact support if the problem
          persists.
        </Text>
        {error && (
          <Text color="red.500" fontSize="14px">
            {error?.message}
          </Text>
        )}
      </Card>
    </Flex>
  )
}

ErrorComponent.propTypes = {
  error: PropTypes.string,
  clearError: PropTypes.func,
  errorInfo: PropTypes.string,
}

export default ErrorComponent
