import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useApp } from 'context/App'
import { getTenantList, postTenantSwitch } from 'context/Auth/api'
import {
  Button,
  Box,
  useToast,
  Spinner,
  Select,
  Heading,
} from '@chakra-ui/react'
import { useAuth } from 'context/Auth'

const CrossTenantAccessForm = () => {
  const { setAccessToken } = useAuth()
  const { handleError } = useApp()
  const toast = useToast()

  const [tenantOptions, setTenantOptions] = useState([])
  const [activeTenant, setActiveTenant] = useState('')
  const [selectedTenant, setSelectedTenant] = useState('')

  const { isLoading: isLoadingTenantList, refetch: refetchTenantList } =
    useQuery('getTenantList', getTenantList, {
      onSuccess: (response) => {
        const foundActiveTenant = response?.data?.find(
          ({ current_tenant }) => current_tenant === true
        )?.uuid
        setSelectedTenant(foundActiveTenant)
        setActiveTenant(foundActiveTenant)
        setTenantOptions(response?.data)
      },
    })

  const handleSuccess = (response) => {
    setAccessToken(response.data?.access)
    refetchTenantList()
    const selectedTenantName =
      tenantOptions.find((tenant) => tenant.uuid === selectedTenant)?.name ||
      'updated'
    toast({
      position: 'top',
      title: 'Active Tenant Changed!',
      description: `The tenant you are viewing and modifying is now ${selectedTenantName}. `,
      status: 'success',
      duration: 4000,
      isClosable: true,
    })
  }

  const { isLoading: isLoadingSwitchTenant, mutate: switchTenant } =
    useMutation(postTenantSwitch, {
      onSuccess: handleSuccess,
      onError: handleError,
    })

  const disabledStatus =
    isLoadingSwitchTenant ||
    isLoadingTenantList ||
    activeTenant === selectedTenant

  return (
    <Box>
      <Heading mb="16px" size="sub2">
        Tenant Access
      </Heading>
      {isLoadingTenantList ? (
        <Box px="16px" my="16px">
          <Spinner size="sm" />
        </Box>
      ) : (
        <Select
          mb="16px"
          label="Active Tenant"
          value={selectedTenant}
          onChange={(e) => setSelectedTenant(e.target.value)}
        >
          {tenantOptions.map(({ uuid, name, primary_tenant }) => (
            <option key={uuid} value={uuid}>
              {name} {primary_tenant ? '(Primary Tenant)' : ''}
            </option>
          ))}
        </Select>
      )}

      <Button
        mr="8px"
        mb="16px"
        onClick={() => switchTenant(selectedTenant)}
        isLoading={isLoadingSwitchTenant}
        loadingText="Submitting"
        disabled={disabledStatus}
      >
        Set Active Tenant
      </Button>
    </Box>
  )
}

CrossTenantAccessForm.defaultProps = {}
CrossTenantAccessForm.propTypes = {}

export default CrossTenantAccessForm
