import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Stack, Heading, useToast } from '@chakra-ui/react'
import { useAuth } from 'context/Auth'
import MFADelete from './MFADelete'
import MFASetup from './MFASetup'
import { initiateResetPassword } from 'context/Auth/api'
import { useApp } from 'context/App'
import { useMutation } from 'react-query'
import CrossTenantUserForm from './CrossTenantAccessForm'
// import TenantSettings from './TenantSelect'

const UserSettings = ({ onOpenEula }) => {
  const { handleError } = useApp()
  const { mfaEnabled, mfaVerified, userEmail, isSsoEnabled } = useAuth()
  const toast = useToast()
  const isMfaSetup = mfaEnabled || mfaVerified

  const { isLoading, mutate: sendResetPasswordEmail } = useMutation(
    initiateResetPassword,
    {
      onSuccess: () => {
        toast({
          title: 'Email Sent!',
          description:
            'Please check your email for a link to reset your email.',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'top',
        })
      },
      onError: handleError,
    }
  )

  return (
    <Stack maxWidth="480px" spacing="24px">
      <CrossTenantUserForm />

      {!isSsoEnabled && (
        <Box maxW="480px">
          <Heading mb="16px" size="sub2">
            {isMfaSetup
              ? 'Remove Multi-factor Authentication'
              : 'Enable Multi-factor Authentication'}
          </Heading>
          {isMfaSetup ? <MFADelete /> : <MFASetup />}
        </Box>
      )}

      <Box>
        <Heading my="16px" size="sub2">
          Change User Password
        </Heading>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          loadingText="Sending Email"
          onClick={() => sendResetPasswordEmail(userEmail)}
        >
          Send Reset Email
        </Button>
      </Box>

      <Box>
        <Heading my="16px" size="sub2">
          Terms & Conditions
        </Heading>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          loadingText="Sending Email"
          onClick={onOpenEula}
        >
          View or Sign
        </Button>
      </Box>

      {/* <TenantSettings /> */}
    </Stack>
  )
}

UserSettings.propTypes = {
  onOpenEula: PropTypes.func,
}

export default UserSettings
