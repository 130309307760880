import React from 'react'
import PropTypes from 'prop-types'
import {
  Popover,
  PopoverTrigger,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  ButtonGroup,
  Button,
  Text,
  PopoverCloseButton,
} from '@chakra-ui/react'

const SSODeleteConfirmation = ({ children, onRemoveSSO }) => {
  const initialFocusRef = React.useRef()

  return (
    <Popover initialFocusRef={initialFocusRef} placement="bottom">
      {({ onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent>
            <PopoverHeader pt={4} fontWeight="bold" border="0">
              Disable SSO
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Text>
                Are you sure you want to remove SSO for your this tenant? This
                action will be applied to your entire organization and is not
                able to be undone.
              </Text>
            </PopoverBody>
            <PopoverFooter
              border="0"
              d="flex"
              alignItems="center"
              justifyContent="flex-end"
              pb={4}
            >
              <ButtonGroup size="sm">
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onRemoveSSO()
                    onClose()
                  }}
                >
                  Remove SSO
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

SSODeleteConfirmation.propTypes = {
  children: PropTypes.any,
  onRemoveSSO: PropTypes.func,
}

export default SSODeleteConfirmation
