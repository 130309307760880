import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from '@chakra-ui/react'
import { PRODUCTS } from 'context/Programs/constants'

const getTextBody = (product) => {
  switch (product) {
    case PRODUCTS.azureActiveDirectory:
      return (
        <>
          <Text mb="8px" fontSize="14px">
            Connecting Azure Active Directory (Azure AD) to Reach enables you to
            dynamically manage intelligence on your riskiest users, your Most
            Attacked People (MAP). Your teams can operationalize this intel by
            applying new and/or enhanced security initiatives to your MAP.
          </Text>
        </>
      )
    case PRODUCTS.microsoft:
      return (
        <>
          <Text mb="8px" fontSize="14px">
            Connecting your Microsoft Suite to Reach enables you to integrate
            multiple Microsoft Security products to Reach with a single click.
            We recommend connecting this application to Reach if you are a
            Microsoft E5 and/or Protection Pack 2 Security Suite customer.
          </Text>
        </>
      )
    case PRODUCTS.activeDirectory:
      return (
        <>
          <Text mb="8px" fontSize="14px">
            Uploading metadata from the Active Directory you manage to Reach
            enables you to dynamically manage intelligence on your riskiest
            users, your Most Attacked People (MAP). Your teams can
            operationalize this intel by applying new and/or enhanced security
            initiatives to your MAP.
          </Text>
        </>
      )
    case PRODUCTS.sentinelOne:
      return (
        <>
          <Text mb="8px" fontSize="14px">
            Connecting SentinelOne to Reach will allow your team to
            automatically customize your EDR product to adapt to the risks
            facing your environment. Reach will also leverage events and attack
            metadata to improve security configurations of other products.
          </Text>
        </>
      )
    case PRODUCTS.proofpoint:
      return (
        <Text fontSize="14px">
          Connecting Proofpoint will allow you to make improved configuration
          decisions across your stack by leveraging the security value of email
          sandbox forensics and attack metrics (e.g. delivered, blocked).
        </Text>
      )
    case PRODUCTS.proofpointPps:
      return (
        <>
          <Text mb="8px" fontSize="14px">
            Connecting PPS to Reach will allow you to dynamically customize your
            Email protection product to adapt to risks that are proprietary to
            your organization.
          </Text>
        </>
      )
    case PRODUCTS.crowdStrike:
      return (
        <Text fontSize="14px">
          Connecting CrowdStrike to Reach will allow you to make improved
          configuration decisions across your stack by providing ...
        </Text>
      )
    case PRODUCTS.okta:
      return (
        <Text fontSize="14px">
          Connecting Okta to Reach will allow you to make improved configuration
          decisions across your stack by providing ...
        </Text>
      )
    case PRODUCTS.paloAlto:
      return (
        <Text fontSize="14px">
          Reach will leverage events and attack metadata from other products to
          improve the configurations of your firewalls or Prisma Access.
        </Text>
      )
    default:
      return <Box />
  }
}

export const ProductDescription = ({ product, ...remaining }) => {
  const textBody = getTextBody(product)

  return <Box {...remaining}>{textBody}</Box>
}

ProductDescription.propTypes = {
  product: PropTypes.string,
}

export default ProductDescription
