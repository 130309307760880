import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Center, Flex, Icon, useToast } from '@chakra-ui/react'
import HeaderDrawer from 'components/Shared/HeaderDrawer'
import { VscDebugDisconnect } from 'react-icons/vsc'
import { AiOutlineDelete } from 'react-icons/ai'
import ProductImage, {
  getProductName,
} from 'components/Shared/Product/ProductDetails'
import SetupConnectionDrawer from 'components/Shared/Connections/SetupConnectionDrawer'
import { deleteCredentials } from 'context/App/api'
import { useApp } from 'context/App'
import { useMutation } from 'react-query'
import ConfirmDeletePopover from './ConfirmDeletePopover'

const RemoveChildConnectionsDrawer = ({
  isOpen,
  onClose,
  product,
  conflictingChildren,
  ...remaining
}) => {
  const toast = useToast()
  const { handleError } = useApp()

  // batch operation for deleting all credentials
  const {
    mutate: onDeleteCredentials,
    isLoading,
    isSuccess,
  } = useMutation(
    (conflictingChildren) => {
      const deletePromises = []
      conflictingChildren.forEach((conflictChild) =>
        deletePromises.push(deleteCredentials({ id: conflictChild.uuid }))
      )
      return Promise.all(deletePromises)
    },
    {
      onSuccess: () => {
        toast({
          title: 'Connections Removed',
          description: 'Conflicting products disconnected successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'top',
        })
      },
      onError: handleError,
    }
  )

  const handleDeleteCredentials = () => {
    onDeleteCredentials(conflictingChildren)
  }

  if (!conflictingChildren || conflictingChildren?.length === 0) {
    return (
      <SetupConnectionDrawer
        isOpen={isOpen}
        onClose={onClose}
        {...remaining}
        onConnectionSuccess={() => {
          onClose()
        }}
      />
    )
  }

  let body
  body = (
    <Box py="24px">
      <Box mb="24px">
        <Box mb="16px" fontSize="20px" fontWeight="medium">
          It looks like you have conflicting connections in standalone mode:
        </Box>
        {conflictingChildren?.map?.((conflictProduct) => (
          <Flex key={conflictProduct?.uuid} py="8px">
            <ProductImage
              mr="12px"
              w="24px"
              product={conflictProduct?.product}
            />
            <Box fontSize="20px">
              {getProductName(conflictProduct?.product)}
            </Box>
          </Flex>
        ))}
      </Box>
      <Box mb="32px">
        Connecting {getProductName(product)} will also establish a connection to
        some applications you have in standalone mode. To add this connection
        you must first remove those connections.
      </Box>
      <Center>
        <ConfirmDeletePopover
          product={product}
          onConfirm={handleDeleteCredentials}
          isLoading={isLoading}
          isSuccess={isSuccess}
        >
          <Button
            disabled={isLoading || isSuccess}
            loadingText="Removing Conflicting Connections"
            colorScheme="red"
            rightIcon={<Icon as={AiOutlineDelete} />}
            size="lg"
            mb="24px"
          >
            Remove Connections
          </Button>
        </ConfirmDeletePopover>
      </Center>
    </Box>
  )

  return (
    <HeaderDrawer
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      icon={false}
      header={
        <Flex mb="16px">
          <Icon w="30px" h="30px" mr="16px" as={VscDebugDisconnect} />
          Microsoft Product Setup
        </Flex>
      }
      subHeader={
        'Connecting Microsoft Suite when you have an active E5 or Protection Plan account will grant access to Defender for O365 and Azure Entra.'
      }
      body={body}
    />
  )
}

RemoveChildConnectionsDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  product: PropTypes.string,
  conflictingChildren: PropTypes.array,
}

export default RemoveChildConnectionsDrawer
