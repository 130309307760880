import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

import UsernamePasswordForm from './Forms/UsernamePassword'

import { CONNECTION_METHODS } from '../constants'
import ApiKeyForm from './Forms/ApiKeyForm'
import OAuthForm from './Forms/OAuthForm'
import ClientSecretForm from './Forms/ClientSecretForm'
import FileSnapshotForm from './Forms/FileSnapshotForm'
import UsernamePasswordHostnameForm from './Forms/UsernamePasswordHostname'
import NoneConnectionForm from './Forms/NoneConnectionForm'

const getProductAuthForm = (product, authMethod, props) => {
  if (authMethod === CONNECTION_METHODS.USERNAME_PASSWORD) {
    return <UsernamePasswordForm {...props} />
  }
  if (authMethod === CONNECTION_METHODS.USERNAME_PASSWORD_HOSTNAME) {
    return <UsernamePasswordHostnameForm {...props} />
  }
  if (authMethod === CONNECTION_METHODS.API_KEY) {
    return <ApiKeyForm {...props} />
  }
  if (authMethod === CONNECTION_METHODS.OAUTH) {
    return <OAuthForm {...props} />
  }
  if (authMethod === CONNECTION_METHODS.CLIENT_SECRET) {
    return <ClientSecretForm {...props} />
  }
  if (
    authMethod === CONNECTION_METHODS.DIRECT_UPLOAD ||
    authMethod === CONNECTION_METHODS.FILE_SNAPSHOT
  ) {
    return <FileSnapshotForm {...props} />
  }
  if (authMethod === CONNECTION_METHODS.NONE) {
    return <NoneConnectionForm {...props} />
  }
}

export const ConnectionSetup = ({
  application,
  product,
  onError,
  onSuccess,
  authMethod,
  setSubmitButton,
  ...remaining
}) => {
  const form = getProductAuthForm(product, authMethod, {
    onError,
    onSuccess,
    product,
    setSubmitButton,
    application,
  })

  if (!form) return null

  return <Box {...remaining}>{form}</Box>
}

ConnectionSetup.propTypes = {
  authMethod: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setSubmitButton: PropTypes.func,
  application: PropTypes.object,
  product: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
}

export default ConnectionSetup
