import React from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import { Box, useColorModeValue } from '@chakra-ui/react'
import { isMacOs } from 'utils'

const ScrollArea = React.forwardRef(
  (
    { children, disableHorizontalScroll, disableVerticalScroll, ...props },
    ref
  ) => {
    const thumbColor = useColorModeValue('gray.800', 'gray.100')
    const trackColor = useColorModeValue('#0001', '#8882')
    const trackColorHover = useColorModeValue('#0003', '#8885')

    if (isMacOs()) {
      return (
        <Box overflow="auto" ref={ref} {...props}>
          {children}
        </Box>
      )
    }

    return (
      <Scrollbars
        autoHide
        hideTracksWhenNotNeeded
        renderThumbVertical={(props) => (
          <Box
            {...props}
            bg={thumbColor}
            opacity={0.5}
            _hover={{ opacity: 0.75 }}
            _active={{ opacity: 1 }}
            borderRadius="3px"
            className="thumb-vertical"
          />
        )}
        renderTrackVertical={(props) => (
          <Box
            right="2px"
            bottom="2px"
            top="2px"
            borderRadius="3px"
            bg={trackColor}
            _hover={{ background: trackColorHover }}
            width="10px !important"
            className="track-vertical"
            display={disableVerticalScroll ? 'none' : 'block'}
            {...props}
          />
        )}
        renderThumbHorizontal={(props) => (
          <Box
            bg={thumbColor}
            opacity={0.5}
            _hover={{ opacity: 0.75 }}
            _active={{ opacity: 1 }}
            borderRadius="3px"
            className="thumb-horizontal"
            {...props}
          />
        )}
        renderTrackHorizontal={(props) => (
          <Box
            right="2px"
            bottom="2px"
            left="2px"
            borderRadius="3px"
            bg={trackColor}
            height="10px !important"
            className="track-horizontal"
            display={disableHorizontalScroll ? 'none' : 'block'}
            {...props}
          />
        )}
        {...props}
        ref={ref}
      >
        {children}
      </Scrollbars>
    )
  }
)

ScrollArea.defaultProps = {
  disableVerticalScroll: false,
  disableHorizontalScroll: true,
}

ScrollArea.propTypes = {
  children: PropTypes.any,
  disableHorizontalScroll: PropTypes.bool,
  disableVerticalScroll: PropTypes.bool,
}

export default ScrollArea
