import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Center } from '@chakra-ui/react'
import {
  postConnectionCredentials,
  //   postSnapshotConfirm,
} from 'context/App/api'
import { useMutation } from 'react-query'
import { useApp } from 'context/App'
import { CONNECTION_METHODS } from '../../constants'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { getProductName } from 'components/Shared/Product/ProductDetails'

const NoneConnectionForm = ({ product, onSuccess }) => {
  const { handleError } = useApp()

  const { isLoading: isGeneratingApp, mutate: generateActiveDirectoryApp } =
    useMutation(
      () =>
        postConnectionCredentials({
          product: product,
          authMethod: CONNECTION_METHODS.NONE,
          credentials: {},
        }),
      { onError: handleError, onSuccess: onSuccess }
    )

  return (
    <Center>
      <Button
        size="lg"
        rightIcon={<Icon w="20px" h="20px" as={AiOutlinePlusCircle} />}
        onClick={generateActiveDirectoryApp}
        isLoading={isGeneratingApp}
      >
        Enable {getProductName(product)}
      </Button>
    </Center>
  )
}

NoneConnectionForm.propTypes = {
  setSubmitButton: PropTypes.func,
  authMethod: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSuccess: PropTypes.func,
  application: PropTypes.object,
  product: PropTypes.string,
  onError: PropTypes.func,
}

export default NoneConnectionForm
