import {
  RiScalesLine,
  RiSkull2Line,
  // RiSkull2Line,
  RiUserSearchLine,
  RiUserUnfollowLine,
} from 'react-icons/ri'
import { VscPerson } from 'react-icons/vsc'
import {
  AiOutlineAlert,
  // AiOutlineAlert,
  AiOutlineBank,
  AiOutlineCalculator,
  AiOutlineFund,
  AiOutlineMail,
  AiOutlineNotification,
  AiOutlinePieChart,
  AiOutlineQuestionCircle,
} from 'react-icons/ai'
import {
  BsBarChartLine,
  BsBriefcase,
  BsGear,
  BsHddNetwork,
  BsMailbox,
  BsPen,
} from 'react-icons/bs'
import { ImUserTie } from 'react-icons/im'
import {
  IoBusinessOutline,
  IoEarthOutline,
  IoFishOutline,
} from 'react-icons/io5'
import { MdOutlineHeadsetMic } from 'react-icons/md'
import { BiAtom } from 'react-icons/bi'

export const getGroupRoleExtraDetailsById = (id) => {
  switch (id) {
    case -32768:
      return {
        icon: IoEarthOutline,
        favorite: true,
        hiddenFromRoles: true,
      }
    case -5:
      return {
        name: 'Most Attacked: Phishing',
        icon: IoFishOutline,
        favorite: true,
        hiddenFromRoles: true,
        isMetaGroup: true,
        isMostAttacked: true,
      }
    case -6:
      return {
        name: 'Most Attacked: Malware',
        icon: RiSkull2Line,
        favorite: true,
        hiddenFromRoles: true,
        isMetaGroup: true,
        isMostAttacked: true,
      }
    case -4:
      return {
        name: 'Most Attacked: Overall',
        icon: AiOutlineAlert,
        favorite: true,
        hiddenFromRoles: true,
        isMetaGroup: true,
        isMostAttacked: true,
      }
    case -3:
      return {
        name: 'Shared Mailboxes',
        icon: BsMailbox,
        isMetaGroup: true,
      }
    case -2:
      return {
        name: 'Missing Users',
        icon: RiUserSearchLine,
        hidden: true,
        isMetaGroup: true,
      }
    case -1:
      return {
        name: 'Inactive Users',
        icon: RiUserUnfollowLine,
        isMetaGroup: true,
      }
    case 0:
      return {
        name: 'Unknown',
        icon: AiOutlineQuestionCircle,
        isMetaGroup: true,
        hidden: true,
      }
    case 1:
      return {
        name: 'Executives',
        icon: ImUserTie,
      }
    case 2:
      return { name: 'Payroll', icon: AiOutlineBank }
    case 3:
      return {
        name: 'Research & Development',
        icon: BiAtom,
      }
    case 4:
      return { name: 'Legal', icon: RiScalesLine }
    case 5:
      return { name: 'Finance', icon: AiOutlinePieChart }
    case 6:
      return { name: 'Accounting', icon: AiOutlineCalculator }
    case 7:
      return { name: 'Human Resources', icon: VscPerson }
    case 8:
      return {
        name: 'Support',
        icon: MdOutlineHeadsetMic,
      }
    case 9:
      return { name: 'Operations', icon: BsGear }
    case 10:
      return { name: 'Information Technologies', icon: BsHddNetwork }
    case 11:
      return {
        name: 'Marketing',
        icon: AiOutlineNotification,
      }
    case 12:
      return { name: 'Business', icon: AiOutlineFund }
    case 13:
      return { name: 'Services', icon: BsBarChartLine }
    case 14:
      return {
        name: 'Sales',
        icon: BsBriefcase,
      }
    case 15:
      return { name: 'Office & Administration', icon: IoBusinessOutline }
    case 16:
      return { name: 'Contingent Workers', icon: BsPen }
    case 32767:
      return {
        name: 'Distribution Lists',
        icon: AiOutlineMail,
        isMetaGroup: true,
      }

    default:
      return null
  }
}
