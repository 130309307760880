import React, { useEffect, useState } from 'react'
import xml2js from 'xml2js'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import CopyButton from 'components/Shared/CopyButton'
import { TabHeading } from '..'
import SSODeleteConfirmation from './SSODeleteConfirmation'
import SetupSSOFormXml from './SetupSSOFormXml'
import { useAuth } from 'context/Auth'
import { useMutation } from 'react-query'
import { deleteSsoConfiguration } from 'context/Auth/api'
import SetupSSOFormMetadata from './SetupSSOFormMetadataUrl'
import { useApp } from 'context/App'
import { AiOutlineDelete } from 'react-icons/ai'

const SSO = () => {
  const { isSsoEnabled, ssoConfiguration, refetchSsoConfigurations } = useAuth()
  const { handleError } = useApp()
  const toast = useToast()

  const [entityId, setEntityId] = useState('')
  const [ssoUrl, setSsoUrl] = useState('')

  useEffect(() => {
    if (ssoConfiguration?.configuration_xml) {
      const parser = new xml2js.Parser()
      parser.parseString(ssoConfiguration?.configuration_xml, (err, data) => {
        setEntityId(data?.['md:EntityDescriptor']?.['$']?.entityID)
        setSsoUrl(
          data?.['md:EntityDescriptor']?.['md:IDPSSODescriptor']?.[0]?.[
            'md:SingleSignOnService'
          ]?.[1]?.$?.Location
        )
      })
    }
  }, [ssoConfiguration])

  const [shouldUseMetadataForm, setShouldUseMetadataForm] = useState(true)

  const copyBgColoy = useColorModeValue('gray.100', 'gray.800')

  const handleChangeFormType = () => setShouldUseMetadataForm((prev) => !prev)
  const handleRemoveSSOSuccess = () => {
    toast({
      title: 'SSO Connection Removed!',
      description:
        'Users will no longer be able to log in with your SSO provider, and will need to use their Reach account information.',
      status: 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    })
    refetchSsoConfigurations()
  }

  const { mutate: deleteSSO, isLoading } = useMutation(deleteSsoConfiguration, {
    onSuccess: handleRemoveSSOSuccess,
    onError: (error) => {
      handleError(error, { title: 'Deleting SSO Failed' })
    },
  })

  return (
    <Flex flexDir="column" h="100vh">
      <TabHeading>Single Sign On Configuration</TabHeading>
      <Box fontWeight="light">
        You can connect Okta, Azure, OneLogin, or your own SAML 2.0 solution.
        Please be aware that configuring SSO is a global operation and will
        affect all current and future users added to this tenant.
      </Box>
      <Box fontWeight="light">
        For more information on SSO setup, please refer to the{' '}
        <Link
          fontWeight="medium"
          href="https://docs.reach.security/docs/saml_onboarding"
          target="_blank"
        >
          Documentation
        </Link>
        .
      </Box>

      {isSsoEnabled && (
        <>
          <Box maxW="600px" mt="48px">
            <Heading size="sub" mb="16px">
              SSO enabled for your tenant
            </Heading>
            <SSODeleteConfirmation onRemoveSSO={deleteSSO}>
              <Button
                isLoading={isLoading}
                loadingText="Deleting SSO"
                colorScheme="red"
                variant="ghost"
                leftIcon={
                  <Icon mb="4px" w="20px" h="20px" as={AiOutlineDelete} />
                }
              >
                Remove SSO
              </Button>
            </SSODeleteConfirmation>
          </Box>

          <Box maxW="600px" mt="24px">
            <Box mb="32px">
              <Heading my="16px" size="sub3">
                SSO Url
              </Heading>
              <Box
                position="relative"
                bg={copyBgColoy}
                borderRadius="8px"
                p="16px"
              >
                <Box overflow="auto" whiteSpace="pre" pr="96px" opacity=".66">
                  {ssoUrl}
                </Box>
                <CopyButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  size="sm"
                  content={ssoUrl}
                />
              </Box>
            </Box>
            <Box mb="32px">
              <Heading my="16px" size="sub3">
                Entity ID
              </Heading>
              <Box
                position="relative"
                bg={copyBgColoy}
                borderRadius="8px"
                p="16px"
              >
                <Box overflow="auto" whiteSpace="pre" pr="96px" opacity=".66">
                  {entityId}
                </Box>
                <CopyButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  size="sm"
                  content={entityId}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}

      <Box maxW="600px" mt="24px">
        {!isSsoEnabled && (
          <Box mb="24px">
            <Link onClick={handleChangeFormType}>
              {shouldUseMetadataForm
                ? 'Enter SSO xml manually'
                : 'Use Metadata URL'}
            </Link>
          </Box>
        )}
        {ssoConfiguration?.configuration_xml || !shouldUseMetadataForm ? (
          <SetupSSOFormXml />
        ) : (
          <SetupSSOFormMetadata />
        )}
      </Box>
    </Flex>
  )
}

export default SSO
