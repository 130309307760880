import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Button, Flex, useToast } from '@chakra-ui/react'
import FormInput from 'components/Shared/Form/FormInput'
import FormSelect from 'components/Shared/Form/FormSelect'
import { useMutation } from 'react-query'
import { postUserRegister } from 'context/Auth/api'
import { ROLES } from 'context/Auth/constants'

const RegisterUserForm = ({ onClose, onError }) => {
  const toast = useToast()

  const formSchema = yup.object().shape({
    email: yup.string().required('Email is required'),
    name: yup.string(),
    role: yup
      .string()
      .oneOf(
        ['administrator', 'standard', 'read-only'],
        'Invalid role type selection'
      )
      .required('Role is required'),
  })

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  })

  const handleSuccess = () => {
    toast({
      title: 'User Registeration Initiated',
      description: `A registration email has been sent to ${getValues(
        'email'
      )}.`,
      status: 'success',
      duration: 8000,
      position: 'top',
      isClosable: true,
    })
    onClose()
  }

  const { isLoading, mutate: onRegisterUser } = useMutation(postUserRegister, {
    onError: onError,
    onSuccess: handleSuccess,
  })

  const onSubmit = (values) => {
    onRegisterUser(values)
  }

  const body = (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        name="email"
        type="email"
        label="User Email"
        disabled={isLoading}
        register={register}
        errors={errors}
        data-testid="user-email-input"
      />
      {/* <FormInput
        name="name"
        label="User Name"
        disabled={isLoading}
        register={register}
        errors={errors}
        data-testid="user-name-input"
      /> */}
      <FormSelect
        name="role"
        label="Role"
        disabled={isLoading}
        register={register}
        errors={errors}
        data-testid="user-role-input"
        options={[
          { value: ROLES.STANDARD, label: 'Standard User' },
          { value: ROLES.READ_ONLY, label: 'Read-only User' },
          { value: ROLES.ADMIN, label: 'Admin' },
        ]}
      />
      <Flex mt="48px" mb="8px" justifyContent="flex-end">
        <Button
          mr="8px"
          isLoading={isLoading}
          loadingText="Submitting"
          disabled={isLoading}
          type="submit"
          data-testid="submit-add-user-button"
        >
          Submit
        </Button>
        <Button
          type="button"
          disabled={isLoading}
          onClick={onClose}
          variant="ghost"
        >
          Close
        </Button>
      </Flex>
    </form>
  )

  return body
}

RegisterUserForm.defaultProps = {
  onSubmitHook: () => {},
  onClose: () => {},
}

RegisterUserForm.propTypes = {
  onClose: PropTypes.func,
  onError: PropTypes.func,
}

export default RegisterUserForm
