import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useMutation } from 'react-query'
import axios from 'axios'
import { useApp } from 'context/App'
import { useAuth } from 'context/Auth'

const RemoveMFAModal = ({ isOpen, onClose, isLoading, onClickRemoveMfa }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remove Multi-factor Authentication</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to disable Multi-factor authentication for this
          account?
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isLoading}
            onClick={onClickRemoveMfa}
            colorScheme="red"
            data-testid="remove-mfa-confirm-button"
          >
            Yes, Remove it
          </Button>
          <Button mr={3} onClick={onClose} variant="ghost">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

RemoveMFAModal.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onClickRemoveMfa: PropTypes.func,
}

const MFAPanel = () => {
  const { setMfaEnabled, setMfaVerified } = useAuth()
  const { handleError } = useApp()
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleRemoveSuccess = () => {
    toast({
      title: 'MFA Removed!',
      description:
        'Multi-factor authentication is no longer required upon login. You may clear the account from your authenticator device.',
      status: 'success',
      duration: 4000,
      isClosable: true,
      position: 'top',
    })

    setMfaEnabled(false)
    setMfaVerified(null)
    onClose()
  }

  const {
    mutate: handleRemoveMfa,
    isLoading,
    isError,
  } = useMutation(
    () => axios.delete('/api/totp-mfa/remove').catch((e) => Promise.reject(e)),
    {
      onError: handleError,
      onSuccess: handleRemoveSuccess,
    }
  )

  const handleClickMfaRemove = async () => {
    handleRemoveMfa()
  }

  return (
    <>
      <RemoveMFAModal
        isOpen={isOpen}
        onClose={onClose}
        onClickRemoveMfa={handleClickMfaRemove}
        isLoading={!isError && isLoading}
      />
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        loadingText="Removing MFA"
        onClick={onOpen}
        data-testid="remove-mfa-button"
      >
        Remove Multi-factor Auth
      </Button>
    </>
  )
}

MFAPanel.propTypes = {
  mfaConfirmationCode: PropTypes.string,
  mfaConfirmationStatus: PropTypes.string,
  handleMfaConfirmationCodeChange: PropTypes.func,
}

export default MFAPanel
