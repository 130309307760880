import React from 'react'
import PropTypes from 'prop-types'
import {
  Img,
  Center,
  useColorModeValue as mode,
  Tooltip,
} from '@chakra-ui/react'
import { PRODUCTS } from 'context/Programs/constants'

import activeDirectory from 'assets/svg/activeDirectory.svg'
import azure_ad from 'assets/svg/azure_ad.svg'
import azureIdentityProtection from 'assets/images/azure_identity_protection_logo.png'
import crowdStrike from 'assets/svg/crowdstrike.svg'
import microsoft from 'assets/svg/microsoft.svg'
import elastic from 'assets/svg/elastic.svg'
import materialSecurity from 'assets/svg/materialSecurity.svg'
import office365 from 'assets/svg/office365.svg'
import defender from 'assets/images/microsoftDefender.png'
import okta from 'assets/svg/okta.svg'
import paloAlto from 'assets/svg/paloAlto.svg'
import proofPointPps from 'assets/images/proofpoint_pps.jpeg'
import proofPointTap from 'assets/images/proofpoint_tap.png'
import sentinelOne from 'assets/svg/sentinelOne.svg'
import snowflake from 'assets/svg/snowflake.svg'
import splunk from 'assets/svg/splunk.svg'
import strata from 'assets/images/StrataLogo.png'
import prisma from 'assets/images/palo_prisma_access_logo.svg'

export const CONTENT_KEYS = {
  DEPLOYMENT_GUIDE: 'deployment_guide',
  CONFIGURATION_TOOL: 'configuration_tool',
}

export const getProductId = (productName) => {
  return getProductDetails(productName)?.id
}

export const getProductName = (productName) => {
  return getProductDetails(productName)?.name
}

export const getProductContentKeys = (productName) => {
  return getProductDetails(productName)?.contentKeys
}

export const getProductDetails = (productName) => {
  if (!productName || typeof productName !== 'string') return ''
  if (productName === PRODUCTS.activeDirectory) {
    return {
      name: 'Active Directory',
      connectionSuccessMessage: 'Active Directory upload complete',
      connectionSuccessMessageBody:
        'The appplication health status will update after processing is complete. Please wait a few moments.',
      image: activeDirectory,
      id: 'local_ad',
      contentKeys: [],
    }
  }
  if (productName === PRODUCTS.azureActiveDirectory) {
    return {
      name: 'Azure Active Directory',
      connectionSuccessMessage: 'Azure Active Directory connection success!',
      image: azure_ad,
      id: 'azure_ad',
      contentKeys: [],
    }
  }
  if (productName === PRODUCTS.azureActiveDirectoryIdentityProtection) {
    return {
      name: 'Azure Conditional Access',
      connectionSuccessMessage:
        'Azure AD Conditional Access connection success!',
      image: azureIdentityProtection,
      id: 'azure_ad',
      contentKeys: [],
    }
  }
  if (productName === 'CrowdStrike Falcon') {
    return {
      name: 'CrowdStrike Falcon',
      connectionSuccessMessage: 'CrowdStrike connection success!',
      image: crowdStrike,
      id: 'crowdstrike',
      contentKeys: [
        CONTENT_KEYS.CONFIGURATION_TOOL,
        CONTENT_KEYS.DEPLOYMENT_GUIDE,
      ],
    }
  }
  if (productName === PRODUCTS.crowdStrike) {
    return {
      name: 'CrowdStrike',
      connectionSuccessMessage: 'CrowdStrike connection success!',
      image: crowdStrike,
      id: 'crowdstrike',
      contentKeys: [
        CONTENT_KEYS.CONFIGURATION_TOOL,
        CONTENT_KEYS.DEPLOYMENT_GUIDE,
      ],
    }
  }
  if (productName === PRODUCTS.microsoft) {
    return {
      name: 'Microsoft Suite',
      connectionSuccessMessage: 'Microsoft Suite connection success!',
      image: microsoft,
      id: 'microsoft',
      contentKeys: [
        CONTENT_KEYS.CONFIGURATION_TOOL,
        CONTENT_KEYS.DEPLOYMENT_GUIDE,
      ],
    }
  }
  if (productName === PRODUCTS.elastic) {
    return {
      name: 'Elastic',
      connectionSuccessMessage: 'Elastic connection success!',
      image: elastic,
      id: 'elastic',
      contentKeys: [CONTENT_KEYS.CONFIGURATION_TOOL],
    }
  }
  if (productName === PRODUCTS.materialSecurity) {
    return {
      name: 'Material Security',
      connectionSuccessMessage: 'Material Security connection success!',
      image: materialSecurity,
      id: 'material_security',
      contentKeys: [CONTENT_KEYS.CONFIGURATION_TOOL],
    }
  }
  if (productName === PRODUCTS.office365) {
    return {
      name: 'Office 365',
      connectionSuccessMessage: 'Office 365 connection success!',
      image: office365,
      id: 'office_365',
      contentKeys: [CONTENT_KEYS.CONFIGURATION_TOOL],
    }
  }
  if (productName === PRODUCTS.microsoftDefender) {
    return {
      name: 'Defender for O365',
      connectionSuccessMessage: 'Microsoft Defender connection success!',
      image: defender,
      id: 'microsoft_defender',
      contentKeys: [CONTENT_KEYS.CONFIGURATION_TOOL],
    }
  }

  if (
    productName === PRODUCTS.okta ||
    productName === getProductName(PRODUCTS.okta)
  ) {
    return {
      name: 'Okta',
      connectionSuccessMessage: 'Okta connection success!',
      image: okta,
      id: 'okta',
      contentKeys: [
        CONTENT_KEYS.CONFIGURATION_TOOL,
        CONTENT_KEYS.DEPLOYMENT_GUIDE,
      ],
    }
  }
  if (productName === PRODUCTS.paloAlto || productName === 'PAN-OS') {
    return {
      name: 'Palo Alto Networks',
      connectionSuccessMessage: 'Palo Alto Networks connection success!',
      image: paloAlto,
      id: 'panos',
      contentKeys: [
        CONTENT_KEYS.CONFIGURATION_TOOL,
        CONTENT_KEYS.DEPLOYMENT_GUIDE,
      ],
    }
  }
  if (
    productName === PRODUCTS.proofpoint ||
    productName === getProductName(PRODUCTS.proofpoint)
  ) {
    return {
      name: 'Proofpoint TAP',
      connectionSuccessMessage: 'Proofpoint TAP connection success!',
      image: proofPointTap,
      borderRadius: '8%',
      id: 'proofpoint_tap',
      contentKeys: [CONTENT_KEYS.DEPLOYMENT_GUIDE],
    }
  }
  if (
    productName === PRODUCTS.proofpointPps ||
    productName === 'Proofpoint PPS'
  ) {
    return {
      name: 'Proofpoint PPS',
      connectionSuccessMessage: 'Proofpoint PPS connection success!',
      image: proofPointPps,
      borderRadius: '8%',
      id: 'proofpoint_pps',
      contentKeys: [CONTENT_KEYS.DEPLOYMENT_GUIDE],
    }
  }
  if (
    productName === PRODUCTS.sentinelOne ||
    productName === getProductName(PRODUCTS.sentinelOne)
  ) {
    return {
      name: 'SentinelOne',
      connectionSuccessMessage: 'SentinelOne connection success!',
      image: sentinelOne,
      id: 'sentinelone',
      contentKeys: [
        CONTENT_KEYS.CONFIGURATION_TOOL,
        CONTENT_KEYS.DEPLOYMENT_GUIDE,
      ],
    }
  }
  if (productName === PRODUCTS.snowflake) {
    return {
      name: 'Snowflake',
      connectionSuccessMessage: 'Snowflake connection success!',
      image: snowflake,
      id: 'snowflake',
      contentKeys: [CONTENT_KEYS.DEPLOYMENT_GUIDE],
    }
  }
  if (productName === PRODUCTS.splunk) {
    return {
      name: 'Splunk',
      connectionSuccessMessage: 'Splunk connection success!',
      image: splunk,
      id: 'splunk',
      contentKeys: [CONTENT_KEYS.DEPLOYMENT_GUIDE],
    }
  }
  if (productName === PRODUCTS.paloAltoStrata) {
    return {
      name: 'Palo Alto Strata',
      connectionSuccessMessage: 'Palo Alto Strata connection success!',
      image: strata,
      id: 'strata',
      contentKeys: [
        CONTENT_KEYS.CONFIGURATION_TOOL,
        CONTENT_KEYS.DEPLOYMENT_GUIDE,
      ],
    }
  }
  if (productName === PRODUCTS.paloAltoPrisma) {
    return {
      name: 'Palo Alto Prisma Access',
      connectionSuccessMessage: 'Palo Alto Prisma connection success!',
      image: prisma,
      id: 'prisma'
    }
  }
  return { name: productName, connectionSuccessMessage: 'Success', image: '' }
}

const ProductImage = ({
  product,
  hasDarkModeBackground,
  hasTooltip,
  h,
  height,
  ...remaining
}) => {
  let image = activeDirectory
  let borderRadius = '0px'

  const details = getProductDetails(product)

  image = details?.image
  borderRadius = details?.borderRadius
  let imageCenter

  if (hasDarkModeBackground) {
    imageCenter = (
      <Center
        p="6px"
        bg={mode('transparent', '#0004')}
        borderRadius="8px"
        {...remaining}
      >
        <Img
          w="100%"
          h={h || height}
          data-testid="product-image"
          borderRadius={borderRadius}
          src={image}
          alt={details.name}
        />
      </Center>
    )
  } else {
    imageCenter = (
      <Img
        h={h || height}
        data-testid="product-image"
        borderRadius={borderRadius}
        src={image}
        alt={details.name}
        {...remaining}
      />
    )
  }

  if (hasTooltip) {
    return (
      <Tooltip placement="top" label={getProductName(product)}>
        {imageCenter}
      </Tooltip>
    )
  }

  return imageCenter
}

ProductImage.defaultProps = {}

ProductImage.propTypes = {
  hasTooltip: PropTypes.bool,
  hasDarkModeBackground: PropTypes.bool,
  product: PropTypes.string,
  h: PropTypes.string,
  height: PropTypes.string,
}

export default ProductImage
