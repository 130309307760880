import React from 'react'
import PropTypes from 'prop-types'
import {
  Icon,
  Center,
  Tooltip,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const ReachTooltip = ({
  p,
  label,
  tooltip,
  iconSize,
  placement,
  link,
  children,
  shouldWrapChildren,
  ...remaining
}) => {
  const color = mode('reachBlue.500', 'reachBlue.300')
  const icon = (
    <Icon w={iconSize} h={iconSize} color={color} as={AiOutlineInfoCircle} />
  )
  return (
    <Tooltip
      placement={placement}
      bg={mode('reachBlue.500', 'reachBlue.100')}
      hasArrow
      arrowSize={8}
      label={tooltip || label}
      shouldWrapChildren={shouldWrapChildren}
    >
      {children || (
        <Center {...remaining} p={p} borderRadius="50%">
          {link ? (
            <a href={link} target="_blank" rel="noreferrer">
              {icon}
            </a>
          ) : (
            icon
          )}
        </Center>
      )}
    </Tooltip>
  )
}

ReachTooltip.defaultProps = {
  iconSize: '28px',
  p: '4px',
}

ReachTooltip.propTypes = {
  shouldWrapChildren: PropTypes.bool,
  children: PropTypes.any,
  label: PropTypes.string,
  placement: PropTypes.string,
  tooltip: PropTypes.string,
  link: PropTypes.string,
  iconSize: PropTypes.string,
  p: PropTypes.string,
}

export default ReachTooltip
