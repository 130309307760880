import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import MotionBox from './MotionBox'
import {
  useToken,
  useColorModeValue as mode,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'

const Pulser = ({
  isActive,
  children,
  tooltip,
  tooltipInitialDelay,
  ...remaining
}) => {
  // need the useRef for the timeout to get the right value
  const hasClosedRef = useRef(false)
  // need the useState to cause a rerender to update the variant
  const [hasClosedState, setHasClosedState] = useState(false)
  const boxShadowColor = mode(
    useToken('colors', 'reachTeal.500'),
    useToken('colors', 'reachTeal.400')
  )

  const variants = {
    pulsing: {
      boxShadow: [
        `0 0 0 0px ${boxShadowColor}`,
        `0 0 0 4px ${boxShadowColor}`,
        `0 0 0 0px ${boxShadowColor}`,
      ],
      transition: { repeat: Infinity, duration: 2 },
    },
    closed: {
      boxShadow: `0 0 0 0px ${boxShadowColor}`,
      transition: { duration: 0.7 },
    },
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleClose = () => {
    onClose()
    hasClosedRef.current = true
    setHasClosedState(true)
  }

  useEffect(() => {
    setTimeout(() => {
      if (hasClosedRef.current === false) {
        onOpen()
      }
    }, tooltipInitialDelay)
  }, [])

  const pulser = (
    <MotionBox
      variants={variants}
      animate={hasClosedState === true ? 'closed' : 'pulsing'}
      onMouseEnter={handleClose}
      borderRadius="24px"
      {...remaining}
    >
      {children}
    </MotionBox>
  )

  if (!isActive) {
    return children
  }

  if (!tooltip) {
    return pulser
  }

  return (
    <Tooltip
      isOpen={isOpen}
      label={tooltip}
      placement="right"
      hasArrow
      bg={boxShadowColor}
    >
      {pulser}
    </Tooltip>
  )
}

Pulser.defaultProps = {
  isActive: true,
  tooltipInitialDelay: 750,
}

Pulser.propTypes = {
  isActive: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  tooltipInitialDelay: PropTypes.number,
}

export default Pulser
