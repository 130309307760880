import { Box } from '@chakra-ui/layout'
import PropTypes from 'prop-types'
import React from 'react'

const B = ({ children, ...remaining }) => (
  <Box display="inline" fontWeight="bold" {...remaining}>
    {children}
  </Box>
)

B.propTypes = {
  children: PropTypes.any,
}

export default B
