import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useAuth } from 'context/Auth'
import { useQuery } from 'react-query'
import { getGroupSummaries, getGroupWeeklySummaries } from './api'
import { getGroupRoleExtraDetailsById } from './utils'
import wait from 'waait'

const SecurityProfileContext = createContext()

const useSecurityProfile = () => {
  const context = useContext(SecurityProfileContext)
  if (!context) {
    throw new Error(
      `useSecurityProfile must be used within a SecurityProfileProvider`
    )
  }
  return context
}

function SecurityProfileProvider(props) {
  const [totalRisk, setTotalRisk] = useState(1)
  const [totalOrg, setTotalOrg] = useState({})
  const [totalUsers, setTotalUsers] = useState(1)
  const [maxPerCapitaMultiplier, setMaxPerCapitaMultiplier] = useState(1)
  const [riskByType] = useState({
    malware: 0,
    phishing: 0,
    spearPhishing: 0,
    mitm: 0,
    ddos: 0,
    sqlInjection: 0,
    zeroDayExploit: 0,
    apt: 0,
    ransomware: 0,
    dnsAttack: 0,
    logicVulnerability: 0,
    infrastructureOptimization: 0,
    targetedAttack: 0,
  })
  const [visibilityByType] = useState({
    malware: 0,
    phishing: 0,
    spearPhishing: 0,
    mitm: 0,
    ddos: 0,
    sqlInjection: 0,
    zeroDayExploit: 0,
    apt: 0,
    ransomware: 0,
    dnsAttack: 0,
    logicVulnerability: 0,
    infrastructureOptimization: 0,
    targetedAttack: 0,
  })
  const [people] = useState([])
  const [groups, setGroups] = useState([])

  const { accessToken, isAuthenticated } = useAuth()

  const {
    isLoading: isLoadingGroups,
    refetch: refetchGroupSummaries,
    data: groupSummariesResponse,
    isSuccess: groupSummariesIsSuccess,
  } = useQuery(
    [isAuthenticated, accessToken, 'groupSummaries'],
    () => getGroupSummaries(),
    {
      enabled: Boolean(isAuthenticated && accessToken),
    }
  )

  const {
    refetch: refetchWeeklySummary,
    data: weeklySummaryResponse,
    isLoading: isLoadingWeeklySummary,
  } = useQuery(
    [isAuthenticated, accessToken, 'groupWeeklySummaries'],
    () => getGroupWeeklySummaries(),
    {
      enabled: Boolean(isAuthenticated && accessToken),
    }
  )
  const weeklySummary = weeklySummaryResponse?.data || []

  useEffect(async () => {
    // handle logout state reset
    if (!isAuthenticated) {
      setGroups([])
      return
    }
    // only try to fetch groups when we get a new accessToken
    if (accessToken && isAuthenticated && groups.length === 0) {
      await wait(10)
      refetchGroupSummaries()
      refetchWeeklySummary()
    }
  }, [accessToken, isAuthenticated])

  // when groups are fetched, store some additional processing
  useEffect(() => {
    if (groupSummariesIsSuccess) {
      const summaryData = groupSummariesResponse?.data

      const totalOrg = summaryData.find(({ id }) => id === -32768)
      if (!totalOrg) return null
      setTotalRisk(totalOrg.total_risk)
      setTotalOrg(totalOrg)
      setTotalUsers(totalOrg.members)
      const mappedData = [...summaryData].map?.(({ id, ...role }) => {
        return {
          id,
          ...role,
          members_percentage: role.members / (totalOrg?.members || 1),
          // RISK PERCENTAGE calcs
          risk_percentage: role.total_risk / (totalOrg?.total_risk || 1),
          phishing_risk_percentage:
            role.phishing_risk / (totalOrg?.phishing_risk || 1),
          malware_risk_percentage:
            role.malware_risk / (totalOrg?.malware_risk || 1),
          spam_risk_percentage: role.spam_risk / (totalOrg?.spam_risk || 1),
          bec_risk_percentage: role.bec_risk / (totalOrg?.bec_risk || 1),
          // PER CAPITA calcs
          risk_per_capita:
            role.total_risk /
            role.members /
            (totalOrg.total_risk / totalOrg.members),
          phishing_risk_per_capita:
            role.phishing_risk /
            role.members /
            (totalOrg.phishing_risk / totalOrg.members),
          malware_risk_per_capita:
            role.malware_risk /
            role.members /
            (totalOrg.malware_risk / totalOrg.members),
          spam_risk_per_capita:
            role.spam_risk /
            role.members /
            (totalOrg.spam_risk / totalOrg.members),
          bec_risk_per_capita:
            role.bec_risk /
            role.members /
            (totalOrg.bec_risk / totalOrg.members),
          program_count: 1,
          ...getGroupRoleExtraDetailsById(id),
        }
      })

      // find max multiplier to use as a denominator for speedometers
      setMaxPerCapitaMultiplier(
        mappedData.reduce(
          (agg, g) =>
            Math.max(
              g.risk_per_capita,
              g.phishing_risk_per_capita,
              g.malware_risk_per_capita,
              g.spam_risk_per_capita,
              g.bec_risk_per_capita,
              agg
            ),
          0
        )
      )
      setGroups(mappedData)
    }
  }, [isLoadingGroups])

  // utility to favorite / unfavorite by id
  const setGroupFavorited = (id, favorite) => {
    setGroups(
      groups.map((g) => ({
        ...g,
        favorite: g.id === id ? favorite : g.favorite,
      }))
    )
  }

  // getter utility
  const getGroupById = (id) => {
    return groups.find((g) => g.id === id)
  }

  const value = useMemo(
    () => ({
      maxPerCapitaMultiplier,
      weeklySummary,
      isLoadingWeeklySummary,
      refetchWeeklySummary,
      riskByType,
      visibilityByType,
      people,
      groups,
      isLoadingGroups,
      getGroupById,
      setGroupFavorited,
      totalOrg,
      totalRisk,
      totalUsers,
    }),
    [
      maxPerCapitaMultiplier,
      weeklySummary,
      isLoadingWeeklySummary,
      riskByType,
      isLoadingGroups,
      visibilityByType,
      people,
      groups,
      totalOrg,
      totalRisk,
      totalUsers,
    ]
  )

  return <SecurityProfileContext.Provider value={value} {...props} />
}

export { SecurityProfileProvider, useSecurityProfile }
