import {
  GiFishingHook,
  GiSpearfishing,
  GiHood,
  GiSyringe,
  GiEyeTarget,
} from 'react-icons/gi'
import { ImFire } from 'react-icons/im'
import { RiFolderLockFill } from 'react-icons/ri'
import { IoIosPeople } from 'react-icons/io'
import { FaServer } from 'react-icons/fa'
import { FiTarget } from 'react-icons/fi'
import { BiBrain } from 'react-icons/bi'
import { IoSkullSharp } from 'react-icons/io5'

export const SECURITY_ACTIONS = {
  monitor: 'monitor',
  block: 'block',
  hold: 'hold',
}

export const PRODUCTS = {
  azureActiveDirectory: 'azure_ad',
  azureActiveDirectoryIdentityProtection: 'azure_ad_id_protection',
  activeDirectory: 'local_ad',
  crowdStrike: 'crowdstrike',
  elastic: 'Elastic',
  materialSecurity: 'Material Security',
  office365: 'Office 365',
  microsoft: 'reach_security_ms_security_application',
  microsoftDefender: 'defender_office_365',
  okta: 'okta',
  paloAlto: 'panos',
  paloAltoStrata: 'Palo Alto Networks Strata',
  paloAltoPrisma: 'Palo Alto Prisma Access',
  proofpoint: 'proofpoint_tap',
  proofpointPps: 'proofpoint_pps',
  sentinelOne: 'sentinelone',
  snowflake: 'Snowflake',
  splunk: 'Splunk',
}

export const SECURITY_TYPE = {
  malware: {
    id: 'malware',
    name: 'Malware',
    exposureArea: 'overall attack exposure',
    description:
      'Malware attacks are the most common type of cyberattack. Malware is defined as malicious software, including spyware, ransomware, viruses, and worms, which gets installed into the system when the user clicks a dangerous link or email. Once inside the system, malware can block access to critical components of the network, damage the system, and gather confidential information, among others.',
    color: '#8C5FEB',
    icon: IoSkullSharp,
  },
  phishing: {
    id: 'phishing',
    name: 'Phishing',
    exposureArea: 'phishing attack exposure',
    description:
      'Cybercriminals send malicious emails that seem to come from legitimate resources. The user is then tricked into clicking the malicious link in the email, leading to malware installation or disclosure of isMetaGroup information like credit card details and login credentials.',
    color: '#5F76EB',
    icon: GiFishingHook,
  },
  infrastructureOptimization: {
    id: 'infrastructureOptimization',
    name: 'an Infrastructure Optimization',
    exposureArea: 'N/A',
    description:
      'Visibility into each location an attacker could successfully compromise an end-user is the first step in implementing successful security capabilities with minimal friction',
    color: '#4f2ef7',
    icon: GiEyeTarget,
  },
  targetedAttack: {
    id: 'targetedAttack',
    name: 'a Targeted Attack',
    exposureArea: 'overall attack exposure',
    description:
      'Executives and those closest to them in the workplace are targets of attacks. It is valuable asset of a mature security program to have specific capabilities in place to executive staff.',
    color: '#4f2ef7',
    icon: GiEyeTarget,
  },
  spearPhishing: {
    id: 'spearPhishing',
    name: 'Spear Phishing',
    exposureArea: 'phishing attack exposure',
    description:
      'Not all end-users in an organization are treated equally by attackers leading to a small amount of those users posing the greatest risk to the business. Risk being unevenly distributed means that focusing security on a specific group of end-users can provide a disproportionate amount of security for the organization.',
    color: '#4f2ef7',
    icon: GiSpearfishing,
  },
  mitm: {
    id: 'mitm',
    name: 'Man in the Middle Attack',
    exposureArea: 'overall attack exposure',
    description:
      'Man in the Middle (MitM) attack occurs when cyber criminals place themselves between a two-party communication. Once the attacker interprets the communication, they may filter and steal isMetaGroup data and return different responses to the user.',
    color: '#43AA8B',
    icon: IoIosPeople,
  },
  ddos: {
    id: 'ddos',
    name: 'Denial of Service Attack (DDoS)',
    exposureArea: 'infrastructure attack exposure',
    description:
      'Denial of Service attacks aims at flooding systems, networks, or servers with massive traffic, thereby making the system unable to fulfill legitimate requests. Attacks can also use several infected devices to launch an attack on the target system. This is known as a Distributed Denial of Service (DDoS) attack.',
    color: '#F45B69',
    icon: ImFire,
  },
  sqlInjection: {
    id: 'sqlInjection',
    name: 'SQL Injection',
    exposureArea: 'overall attack exposure',
    description:
      'A Structured Query Language (SQL) injection attack occurs when cybercriminals attempt to access the database by uploading malicious SQL scripts. Once successful, the malicious actor can view, change, or delete data stored in the SQL database.',
    color: '#8C93A8',
    icon: GiSyringe,
  },
  zeroDayExploit: {
    id: 'zeroDayExploit',
    name: 'Zero-day Exploit',
    exposureArea: 'overall attack exposure',
    description:
      'A zero-day attack occurs when software or hardware vulnerability is announced, and the cybercriminals exploit the vulnerability before a patch or solution is implemented.',
    color: '#EB5F5F',
    icon: FiTarget,
  },
  apt: {
    id: 'apt',
    name: 'Advanced persistent Threats (APT)',
    exposureArea: 'overall attack exposure',
    description:
      'An advanced persistent threat occurs when a malicious actor gains unauthorized access to a system or network and remains undetected for an extended time.',
    color: '#3D348B',
    icon: GiHood,
  },
  ransomware: {
    id: 'ransomware',
    name: 'Ransomware',
    exposureArea: 'overall attack exposure',
    description:
      'Ransomware is a type of malware attack in which the attacker locks or encrypts the victim’s data and threatens to publish or blocks access to data unless a ransom is paid.',
    color: '#858AE3',
    icon: RiFolderLockFill,
  },
  dnsAttack: {
    id: 'dnsAttack',
    name: 'DNS Attack',
    exposureArea: 'overall attack exposure',
    description:
      'A DNS attack is a cyberattack in which cybercriminals exploit vulnerabilities in the Domain Name System (DNS). The attackers leverage the DNS vulnerabilities to divert site visitors to malicious pages (DNS Hijacking) and exfiltrate data from compromised systems (DNS Tunneling).',
    color: '#1098F7',
    icon: FaServer,
  },
  logicVulnerability: {
    id: 'logicVulnerability',
    name: 'a Logic Vulnerability',
    exposureArea: 'tool exploitation exposure',
    description:
      'Logic flaws and product vulnerabilities are commonplace. Many of these flaws are found in features that are not widely used by an organization but are enabled by default. Attackers take advantage of a broader than necessary attack surface.',
    color: '#1098F7',
    icon: BiBrain,
  },
}

export const CONTRIBUTION_SEVERITY = {
  low: { colorScheme: 1, text: 'Low Impact' },
  medium: { colorScheme: 2, text: 'Med Impact' },
  high: { colorScheme: 3, text: 'High Impact' },
}

export const IMPACT_SEVERITY = {
  low: { colorScheme: 3, text: 'Low Cost' },
  medium: { colorScheme: 2, text: 'Med Cost' },
  high: { colorScheme: 1, text: 'High Cost' },
}

export const FRICTION_SEVERITY = {
  low: { colorScheme: 3, text: 'Low Friction' },
  medium: { colorScheme: 2, text: 'Med Friction' },
  high: { colorScheme: 1, text: 'High Friction' },
}
