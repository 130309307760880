import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import RegisterUserForm from './RegisterUserForm'
import HeaderDrawer from 'components/Shared/HeaderDrawer'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { useApp } from 'context/App'
import CrossTenantUserForm from './CrossTenantUserForm'

const RegisterUserModal = ({ isOpen, onClose, refetchUsers }) => {
  const { handleError } = useApp()
  const [crossTenantInviteData, setCrossTenantInviteData] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setCrossTenantInviteData(null)
    }
  }, [isOpen])

  // if the error is a 400 with "already using" message it means
  // the user exists and we want to trigger the cross-tenant dialogue
  const handleRegisterUserError = (e) => {
    if (
      e.response.status === 400 &&
      e.response?.data?.details?.includes('already')
    ) {
      setCrossTenantInviteData(JSON.parse(e.response.config.data))
    } else {
      handleError(e)
    }
  }

  const handleClearCrossTenant = () => setCrossTenantInviteData(null)
  const handleCrossTenantInviteSuccess = () => {
    onClose()
    refetchUsers()
  }

  return (
    <HeaderDrawer
      header="Provision New User"
      subHeader="Instructions will be sent to the provided email to complete the registration process."
      size="sm"
      icon={AiOutlineUserAdd}
      body={
        crossTenantInviteData !== null ? (
          <CrossTenantUserForm
            data={crossTenantInviteData}
            onInviteSuccess={handleCrossTenantInviteSuccess}
            onCancel={handleClearCrossTenant}
          />
        ) : (
          <RegisterUserForm
            onError={handleRegisterUserError}
            onClose={onClose}
          />
        )
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

RegisterUserModal.propTypes = {
  refetchUsers: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default RegisterUserModal
