import React, { useState } from 'react'
import PropTypes from 'prop-types'
import HeaderDrawer from 'components/Shared/HeaderDrawer'
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Text,
  HStack,
  PinInput,
  PinInputField,
  Spinner,
  Link,
} from '@chakra-ui/react'
import { RiLockPasswordLine } from 'react-icons/ri'

const MFASetupDrawer = ({
  isOpen,
  onOpen,
  onClose,
  isLoading,
  mfaSecret,
  base64Image,
  mfaConfirmationCode,
  onMfaConfirmationCodeChange,
}) => {
  const [showSecret, setShowSecret] = useState(false)

  const body = (
    <>
      <Flex flexDir="column">
        <Link
          mb="24px"
          textAlign="center"
          onClick={() => setShowSecret((prev) => !prev)}
        >
          {showSecret ? 'Show QR Code' : 'Show Secret Key'}
        </Link>

        <Box position="relative" h="256px" mb="32px">
          {showSecret && (
            <Center inset="0" position="absolute">
              <Text
                fontWeight="medium"
                textAlign="center"
                data-testid="mfa-secret"
              >
                {mfaSecret}
              </Text>
            </Center>
          )}

          {!showSecret && (
            <Center>
              <Image
                h="256px"
                opacity={isLoading ? 0.25 : 1}
                src={`data:image/png;base64,${base64Image}`}
              />
              {isLoading && (
                <Spinner size="xl" position="absolute" thickness="4px" />
              )}
            </Center>
          )}
        </Box>

        <Center>
          <HStack>
            <PinInput
              size="lg"
              value={mfaConfirmationCode}
              onChange={onMfaConfirmationCodeChange}
              isDisabled={isLoading}
              opacity={isLoading ? 0.2 : 1}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            >
              <PinInputField data-testid="pin-input-0" />
              <PinInputField data-testid="pin-input-1" />
              <PinInputField data-testid="pin-input-2" />
              <PinInputField data-testid="pin-input-3" />
              <PinInputField data-testid="pin-input-4" />
              <PinInputField data-testid="pin-input-5" />
            </PinInput>
          </HStack>
        </Center>
      </Flex>
    </>
  )

  const footer = (
    <Button variant="ghost" onClick={onClose}>
      Cancel
    </Button>
  )

  return (
    <HeaderDrawer
      header="Enable Multi-factor Authentication"
      subHeader="Scan the QR code with your authenticator, and confirm the 6 digit code."
      body={body}
      footer={footer}
      icon={RiLockPasswordLine}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    />
  )
}

MFASetupDrawer.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  mfaSecret: PropTypes.string,
  base64Image: PropTypes.string,
  mfaConfirmationCode: PropTypes.string,
  onMfaConfirmationCodeChange: PropTypes.func,
}

export default MFASetupDrawer
