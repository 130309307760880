import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Icon,
  Flex,
  Center,
  Button,
  Spacer,
  useToast,
  useDisclosure,
  useColorModeValue as mode,
  Link,
} from '@chakra-ui/react'
import { useMutation, useQuery } from 'react-query'
import { useApp } from 'context/App'
import {
  getApplications,
  getApplicationsAuthMethods,
  postConnectionCredentials,
} from 'context/App/api'
import SetupConnectionDrawer from './SetupConnectionDrawer'
import Card from '../Card'
import HealthStatusTick from './HealthStatusTick'
import ProductImage, {
  getProductDetails,
  getProductName,
} from '../Product/ProductDetails'
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlusCircle,
} from 'react-icons/ai'
import { ROLES } from 'context/Auth/constants'
import { useAuth } from 'context/Auth'
import { PRODUCTS } from 'context/Programs/constants'
import MotionBox from '../MotionBox'
import RemoveChildConnectionsDrawer from './RemoveChildConnectionsDrawer'
import ReachTooltip from '../ReachTooltip'

export const CONNECTION_STATUS = {
  HEALTHY: 'healthy',
  ERROR: 'error',
  NOT_CONNECTED: 'not-connected',
}

export const HEALTHY_CONNECTION_IF_ENABLED_MAP = {
  [PRODUCTS.paloAlto]: true,
}

const STATUS_TICK_COUNT = 5

const ConnectionTile = ({
  disabled,
  onClick,
  product,
  setOnPulseParent,
  noHealth,
  connectionStatusOverride,
  buttonOverride,
  noCredentialUpdate,
  onOpenDisconnect,
  disableWhenHealthy,
  onConnectionHealthy,
  hideWhenParentHealthy,
  showOnlyWhenParentHealthy,
  requiredChild,
  ...remainingProps
}) => {
  const [application, setApplication] = useState(null)
  const [connections, setConnections] = useState([])
  const [connectionStatus, setConnectionStatus] = useState(
    connectionStatusOverride
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [connection, setConnection] = useState(null)
  const [parentConnections, setParentConnections] = useState(null)
  const [conflictingChildren, setConflictingChildren] = useState(null)
  const [children, setChildren] = useState(null)
  const parentProduct = connection?.parents?.products?.[0]
  const isStandalone =
    connection?.parents?.stand_alone ||
    JSON.stringify(connection?.parents) === JSON.stringify({})

  const parentHealthy = parentConnections?.[0]?.connections[0]?.is_successful

  // fetch application health
  const {
    isLoading,
    data: healthResponse,
    refetch: refetchHealth,
  } = useQuery('getApplications', getApplications, {
    refetchOnWindowFocus: true,
    refetchInterval: 2000,
  })
  const healthData = healthResponse?.data

  const toast = useToast()
  const { handleError } = useApp()
  const { hasRole } = useAuth()

  const healthColor = mode('reachTeal.500', 'reachTeal.300')
  const errorColor = mode('red.500', 'red.300')
  const childIconBgColor = mode('brand.light', 'gray.900')
  const borderColor = mode('gray.300', 'gray.500')

  // fetch all connection definitions
  const { data: authMethodsResponse } = useQuery(
    'getApplicationsAuthMethods',
    getApplicationsAuthMethods,
    {
      retry: true,
      refetchOnWindowFocus: false,
    }
  )
  const connectionTypes = authMethodsResponse?.data

  const { isLoading: isLoadingAddChild, mutate: mutateConnectionCredentials } =
    useMutation(postConnectionCredentials, {
      onSuccess: () => handleSuccess(product),
      onError: handleError,
    })
  const handleAddChildConnection = () => {
    if (!isLoadingAddChild) {
      mutateConnectionCredentials({
        product: parentProduct,
        authMethod: Object.keys(connection?.auth_methods)?.[0],
        children: [product],
      })
    }
  }

  // if we have auth methods for this product, set the connection
  // also check for any children and parents of our connection
  useEffect(() => {
    const productResponse = connectionTypes?.[product]
    if (productResponse?.auth_methods) {
      setConnection(productResponse)
    }

    if (connectionTypes) {
      const foundChildren = Object.keys(connectionTypes)?.filter(
        (connectionKey) => {
          const conn = connectionTypes[connectionKey]
          return conn?.parents?.products?.includes(product)
        }
      )
      setChildren(foundChildren)
    }
  }, [product, connectionTypes])

  // determine product connection status
  useEffect(() => {
    if (healthData) {
      const foundApp = healthData?.find(
        (connection) => connection?.product === product
      )

      // if the app was found set the connection status
      if (foundApp) {
        setApplication(foundApp)
        // certain connections don't need credentials
        // just to be enabled by the user (like pan-os)
        if (HEALTHY_CONNECTION_IF_ENABLED_MAP[foundApp?.product]) {
          setConnectionStatus(
            connectionStatusOverride || CONNECTION_STATUS.HEALTHY
          )
          onConnectionHealthy?.()
        } else if (foundApp.connections[0]?.is_successful) {
          setConnectionStatus(
            connectionStatusOverride || CONNECTION_STATUS.HEALTHY
          )
          onConnectionHealthy?.()
        } else if (
          foundApp?.connections[0] &&
          !foundApp?.connections[0]?.is_successful
        ) {
          setConnectionStatus(
            connectionStatusOverride || CONNECTION_STATUS.ERROR
          )
        } else {
          setConnectionStatus(
            connectionStatusOverride || CONNECTION_STATUS.NOT_CONNECTED
          )
        }
        setConnections(foundApp?.connections?.slice(0).reverse())
      } else if (!foundApp) {
        // if this app was not found set the connection status to not connected
        setConnectionStatus(
          connectionStatusOverride || CONNECTION_STATUS.NOT_CONNECTED
        )
        setConnections([])
        setApplication(null)
      }
    }
  }, [healthResponse])

  // check children tiles for standalone mode conflicts
  // if we are a parent,
  useEffect(() => {
    if (
      connectionStatus === CONNECTION_STATUS.NOT_CONNECTED &&
      children?.length > 0
    ) {
      const foundConflictingChildren = healthData?.filter((connection) => {
        return (
          children.includes(connection?.product) &&
          connection?.connections?.length > 0
        )
      })
      if (foundConflictingChildren) {
        setConflictingChildren(foundConflictingChildren)
      } else {
        setConflictingChildren(null)
      }
    } else {
      setConflictingChildren(null)
    }
  }, [connectionStatus, healthResponse, children])

  // get parent health if we are a child
  useEffect(() => {
    if (parent) {
      const parentHealthData = healthData?.filter((c) => {
        return connection?.parents?.products?.includes?.(c?.product)
      })
      if (parentHealthData?.length > 0) {
        setParentConnections(parentHealthData)
      } else {
        setParentConnections(null)
      }
    } else {
      setParentConnections(null)
    }
  }, [connectionStatus, healthResponse, parent])

  // if we are a required child and the parent is healthy enable ourself
  useEffect(() => {
    if (
      parentHealthy &&
      connectionStatus === CONNECTION_STATUS.NOT_CONNECTED &&
      requiredChild
    ) {
      handleAddChildConnection()
    }
  }, [parentHealthy, connectionStatus, requiredChild])

  const handleSuccess = () => {
    const details = getProductDetails(product)
    toast({
      title: details?.connectionSuccessMessage,
      description: details?.connectionSuccessMessageBody,
      status: 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    })
    refetchHealth()
    onClose()
  }
  const isNotConnected = connectionStatus === CONNECTION_STATUS.NOT_CONNECTED
  const isHealthy = connectionStatus === CONNECTION_STATUS.HEALTHY
  const isError = connectionStatus === CONNECTION_STATUS.ERROR

  const fillStatusTicks = [
    // we want to map empty status ticks to fill up
    // the status area if there aren't enough entries
    ...Array(Math.max(STATUS_TICK_COUNT - connections.length, 0)),
  ]

  const renderHeaderArea = (
    <>
      {parentProduct ? (
        <Center my="32px" w="100%" h="64px" position="relative">
          <ProductImage
            zIndex={1}
            position="absolute"
            h="64px"
            product={product}
            borderRadius="4px"
          />
          <Center
            bg={childIconBgColor}
            zIndex={2}
            position="absolute"
            transform="translate(32px, -24px)"
            h="32px"
            w="32px"
            p="4px"
            borderRadius="6px"
            border="1px solid"
            borderColor={borderColor}
          >
            <ProductImage h="100%" product={parentProduct} />
          </Center>
        </Center>
      ) : (
        <ProductImage my="32px" maxH="64px" h="100%" product={product} />
      )}
      <Box
        textAlign="center"
        fontSize="20px"
        lineHeight={1.2}
        fontWeight="medium"
        px="24px"
      >
        {getProductName(product)}
      </Box>
      {isHealthy && (
        <Box data-testid={`${product}-healthy-status`} color={healthColor}>
          {noHealth || parentHealthy ? 'Enabled' : 'Connected & Healthy'}
        </Box>
      )}
      {isError && (
        <Box data-testid={`${product}-error-status`} color={errorColor}>
          Connection Error
        </Box>
      )}
      {isNotConnected && (
        <Box
          data-testid={
            isLoading
              ? `${product}-loading-status`
              : `${product}-not-connected-status`
          }
          opacity=".4"
        >
          {isLoading
            ? 'Checking Connection'
            : noHealth
            ? 'Disabled'
            : 'Not Connected'}
        </Box>
      )}
    </>
  )

  // renders the buttons that change depending on the connection status
  let renderButtons
  if (buttonOverride) {
    renderButtons = buttonOverride
  } else if (parentConnections) {
    if (isHealthy) {
      renderButtons = (
        <ReachTooltip
          tooltip={
            requiredChild &&
            `This module is required for ${getProductName(
              parentProduct
            )}. You must remove the parent connection to disconnect this module.`
          }
        >
          <Center flexDir="column" mt="32px" mb="48px">
            <Button
              float="right"
              rightIcon={<Icon w="18px" h="18px" as={AiOutlineCloseCircle} />}
              size="sm"
              disabled={
                (disableWhenHealthy &&
                  connectionStatus === CONNECTION_STATUS.HEALTHY) ||
                disabled ||
                !hasRole(ROLES.STANDARD) ||
                requiredChild
              }
              isLoading={isLoading}
              onClick={() => {
                onClick?.()
                onOpenDisconnect(application)
              }}
              variant="ghost"
              data-testid={`${product}-disconnect-button`}
            >
              Remove Module
            </Button>
          </Center>
        </ReachTooltip>
      )
    } else {
      renderButtons = (
        <Center flexDir="column" mt="32px" mb="48px">
          <Button
            float="right"
            rightIcon={<Icon w="18px" h="18px" as={AiOutlineCheckCircle} />}
            size="sm"
            disabled={
              (disableWhenHealthy &&
                connectionStatus === CONNECTION_STATUS.HEALTHY) ||
              disabled ||
              !hasRole(ROLES.STANDARD)
            }
            isLoading={isLoadingAddChild}
            loadingText="Enabling"
            onClick={() => {
              onClick?.()
              handleAddChildConnection()
            }}
            variant="ghost"
            data-testid={`${product}-disconnect-button`}
          >
            Enable Module
          </Button>
        </Center>
      )
    }
  } else if (isHealthy || isError) {
    renderButtons = (
      <Center flexDir="column" mt="32px" mb="48px">
        {!noCredentialUpdate && isStandalone && (
          <Button
            float="right"
            rightIcon={<Icon w="18px" h="18px" as={AiOutlineEdit} />}
            size="sm"
            disabled={
              (disableWhenHealthy &&
                connectionStatus === CONNECTION_STATUS.HEALTHY) ||
              !hasRole(ROLES.STANDARD)
            }
            isLoading={isLoading}
            onClick={() => {
              onClick?.()
              onOpen()
            }}
            variant="ghost"
            data-testid={`${product}-connection-button`}
          >
            Update Credentials
          </Button>
        )}
        <Button
          float="right"
          rightIcon={<Icon w="18px" h="18px" as={AiOutlineDelete} />}
          size="sm"
          disabled={
            (disableWhenHealthy &&
              connectionStatus === CONNECTION_STATUS.HEALTHY) ||
            disabled ||
            !hasRole(ROLES.STANDARD)
          }
          isLoading={isLoading}
          onClick={() => {
            onClick?.()
            onOpenDisconnect(application)
          }}
          variant="ghost"
          data-testid={`${product}-disconnect-button`}
        >
          Disconnect
        </Button>
      </Center>
    )
  } else {
    if (parentProduct) {
      renderButtons = (
        <Center flexDir="column" mt="32px" mb="32px">
          <Flex flexDir="column" alignItems="center">
            <Center flexDir="column">
              <Box textAlign="center" color={mode('gray.500', 'gray.400')}>
                Connect via{' '}
                <Link onClick={() => setOnPulseParent(true)}>
                  {getProductName(parentProduct)}
                </Link>
              </Box>
            </Center>
          </Flex>
          {isStandalone && (
            <>
              <Box color={mode('gray.500', 'gray.400')} py="2px">
                - or -
              </Box>
              <Button
                float="right"
                rightIcon={<Icon w="18px" h="18px" as={AiOutlinePlusCircle} />}
                size="sm"
                disabled={
                  (disableWhenHealthy &&
                    connectionStatus === CONNECTION_STATUS.HEALTHY) ||
                  disabled ||
                  !hasRole(ROLES.STANDARD)
                }
                isLoading={isLoading}
                onClick={() => {
                  onClick?.()
                  onOpen()
                }}
                variant="ghost"
                data-testid={`${product}-connection-button`}
              >
                Connect Standalone
              </Button>
            </>
          )}
        </Center>
      )
    } else {
      renderButtons = (
        <Center flexDir="column" mt="32px" mb="48px">
          <Button
            float="right"
            rightIcon={<Icon w="18px" h="18px" as={AiOutlinePlusCircle} />}
            size="sm"
            disabled={
              (disableWhenHealthy &&
                connectionStatus === CONNECTION_STATUS.HEALTHY) ||
              disabled ||
              !hasRole(ROLES.STANDARD)
            }
            isLoading={isLoading}
            onClick={() => {
              onClick?.()
              onOpen()
            }}
            variant="ghost"
            data-testid={`${product}-connection-button`}
          >
            Connect
          </Button>
        </Center>
      )
    }
  }

  const renderDrawers =
    conflictingChildren && conflictingChildren?.length > 0 ? (
      <RemoveChildConnectionsDrawer
        isOpen={isOpen}
        onClose={onClose}
        product={product}
        application={application}
        conflictingChildren={conflictingChildren}
      />
    ) : (
      <SetupConnectionDrawer
        isOpen={isOpen}
        onClose={onClose}
        product={product}
        application={application}
        onConnectionSuccess={handleSuccess}
        onConnectionError={handleError}
      />
    )

  // status ticks rendered along bottom of the card
  const renderStatusTicks = (
    <Flex
      bg={mode('gray.100', 'gray.850')}
      w="100%"
      justifyContent="space-around"
      px="12px"
      py="8px"
    >
      {fillStatusTicks.map((_, i) => (
        <HealthStatusTick key={i} />
      ))}
      {connections?.map((connection) => (
        <HealthStatusTick
          key={connection?.connection_time}
          connection={connection}
        />
      ))}
    </Flex>
  )

  if (
    (hideWhenParentHealthy && parentHealthy) ||
    (showOnlyWhenParentHealthy && !parentHealthy)
  ) {
    return null
  }

  return (
    <MotionBox h="100%" layout>
      <Card
        h="100%"
        minH="352px"
        data-testid={`connection-tile-${product}`}
        p="0"
        borderRadius="16px"
        overflow="hidden"
        display="flex"
        flexDir="column"
        alignItems="center"
        {...remainingProps}
      >
        {renderHeaderArea}
        {renderButtons}
        <Spacer flex={1} />
        {noHealth ? (
          <Box h="32px" bg={mode('gray.100', 'gray.850')} w="100%" />
        ) : (
          renderStatusTicks
        )}
      </Card>
      {renderDrawers}
    </MotionBox>
  )
}

ConnectionTile.propTypes = {
  noHealth: PropTypes.bool,
  buttonOverride: PropTypes.node,
  noCredentialUpdate: PropTypes.bool,
  disabled: PropTypes.bool,
  disableWhenHealthy: PropTypes.bool,
  onClick: PropTypes.func,
  onOpenDisconnect: PropTypes.func,
  onConnectionHealthy: PropTypes.func,
  product: PropTypes.string,
  connectionStatusOverride: PropTypes.string,
  setOnPulseParent: PropTypes.func,
  hideWhenParentHealthy: PropTypes.bool,
  showOnlyWhenParentHealthy: PropTypes.bool,
  requiredChild: PropTypes.bool,
}

export default ConnectionTile
