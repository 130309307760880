import axios from 'axios'
import moment from 'moment'

const startDate = moment(new Date().getTime())
  .subtract(3, 'months')
  .format('YYYY-MM-DD')
const endDate = moment(new Date().getTime())
  .subtract(1, 'day')
  .format('YYYY-MM-DD')

export const getGroupRoles = () =>
  axios.get('/api/v1/groups/roles').catch((e) => Promise.reject(e))

export const getGroupSummaries = () => {
  return axios
    .get(`/api/v1/groups/summaries?start_date=${startDate}&end_date=${endDate}`)
    .catch((e) => Promise.reject(e))
}

export const getGroupRoleSummaries = () => {
  return axios
    .get(
      `/api/v1/groups/roles/summaries?start_date=${startDate}&end_date=${endDate}`
    )
    .catch((e) => Promise.reject(e))
}

export const getGroupMostAttackedSummaries = () => {
  return axios
    .get(
      `/api/v1/groups/most-attacked/summaries?start_date=${startDate}&end_date=${endDate}`
    )
    .catch((e) => Promise.reject(e))
}

export const getGroupWeeklySummaries = () => {
  return axios
    .get(
      `/api/v1/groups/weekly-summaries?start_date=${startDate}&end_date=${endDate}`
    )
    .catch((e) => Promise.reject(e))
}

export const getGroupRoleDailySummaries = (groupId) => {
  return axios
    .get(
      `/api/v1/groups/roles/${groupId}/daily-summaries?start_date=${startDate}&end_date=${endDate}`
    )
    .catch((e) => Promise.reject(e))
}

export const getGroupIndividuals = ({
  groupName,
  filter,
  sortBy,
  page = 1,
  start = startDate,
  end = endDate,
}) => {
  return axios
    .get(
      `/api/v1/groups/${groupName}/individuals?start_date=${start}&end_date=${end}&page=${page}&sort_by=${sortBy}&filter=${filter}`
    )
    .catch((e) => Promise.reject(e))
}

export const postTriggerProcessing = (application_uuids) => {
  return axios
    .post('/api/processing/trigger', { application_uuids })
    .catch((e) => Promise.reject(e))
}
