import React from 'react'
import PropTypes from 'prop-types'
import { postRegisterCrossTenantUser } from 'context/Auth/api'
import { useMutation } from 'react-query'
import { useApp } from 'context/App'

import { Button, Flex, Heading, Icon, Text, useToast } from '@chakra-ui/react'
import MotionBox from 'components/Shared/MotionBox'
import B from 'components/Shared/B'
import { ROLE_DISPLAY_NAMES } from 'context/Auth/constants'
import { FaPassport } from 'react-icons/fa'

const CrossTenantUserForm = ({ data, onCancel, onInviteSuccess }) => {
  const { onError } = useApp()
  const toast = useToast()

  const handleSuccess = () => {
    toast({
      title: 'Cross Tenant User Invited!',
      description: `User ${data?.email} has been added to this tenant as a ${data?.role}!`,
      status: 'success',
      duration: 8000,
      position: 'top',
      isClosable: true,
    })
    onInviteSuccess()
  }

  const { isLoading, mutate: onInviteUser } = useMutation(
    postRegisterCrossTenantUser,
    {
      onError: onError,
      onSuccess: handleSuccess,
    }
  )

  const handleSubmit = () => onInviteUser(data)

  return (
    <MotionBox
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ bounce: 0.7 }}
    >
      <Flex alignItems="center" mb="16px">
        <Icon mr="8px" w="24px" h="24px" as={FaPassport} />
        <Heading size="sm">Cross Tenant User</Heading>
      </Flex>
      <Text>
        It looks like <B>{data?.email}</B> already exists on another tenant.
        Would you still like to invite them with the role:{' '}
        <B>{ROLE_DISPLAY_NAMES[data?.role]}</B>?
      </Text>

      <Flex mt="48px" mb="8px" justifyContent="flex-end">
        <Button
          mr="8px"
          isLoading={isLoading}
          loadingText="Inviting"
          disabled={isLoading}
          data-testid="submit-add-cross-tenant-button"
          onClick={handleSubmit}
        >
          Invite User
        </Button>
        <Button
          type="button"
          disabled={isLoading}
          onClick={onCancel}
          variant="ghost"
        >
          Cancel
        </Button>
      </Flex>
    </MotionBox>
  )
}

CrossTenantUserForm.defaultProps = {
  onSubmitHook: () => {},
  onCancel: () => {},
}

CrossTenantUserForm.propTypes = {
  onCancel: PropTypes.func,
  onInviteSuccess: PropTypes.func,
  data: PropTypes.object,
}

export default CrossTenantUserForm
