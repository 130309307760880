import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Box,
  useColorModeValue,
  Flex,
  IconButton,
  Icon,
  Center,
  Spinner,
  useToast,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import moment from 'moment'
import { AiOutlineDelete } from 'react-icons/ai'
import DeletePendingInvitationPopover from './DeletePendingInvitationPopover'
import { useMutation, useQuery } from 'react-query'
import { deletePendingInvitation, getPendingInvitations } from 'context/App/api'
import { useApp } from 'context/App'

const PendingInvitationsModal = ({ onClose, isOpen }) => {
  const { handleError } = useApp()
  const rowColor = useColorModeValue('gray.100', 'gray.700')

  const toast = useToast()

  const {
    isLoading,
    data: response,
    refetch,
  } = useQuery(['getUserInvitations'], getPendingInvitations, {
    refetchOnWindowFocus: false,
  })

  const handleSuccess = () => {
    refetch()
    toast({
      title: 'User Invitation Revoked',
      description:
        'The invitation email sent to the selected user is no longer valid for registration.',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }
  const { mutate: onDeleteInvitation, isLoading: isDeleting } = useMutation(
    deletePendingInvitation,
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  )

  let modalBody = <Box />

  // only render the body if the modal is open
  // small optimization in case the pending invite list is very large
  // short circuits sorting and mapping logic when not necessary
  if (isOpen) {
    modalBody = (
      <>
        <Box fontSize="24px" mb="16px">
          Pending User Invites
        </Box>
        <Box mb="16px">
          This is a list of users that have had an invitation sent to them, but
          have not yet completed registration. You may revoke access if
          necessary.
        </Box>
        {isLoading || !response?.data?.invited_users ? (
          <Center h="200px" w="100%">
            <Spinner />
          </Center>
        ) : (
          <Box>
            <Flex
              w="100%"
              px="16px"
              py="8px"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid"
              borderColor={mode('gray.200', 'gray.600')}
            >
              <Box fontWeight="bold" flex={2}>
                User Email
              </Box>
              <Box fontWeight="bold" flex={0.9}>
                Role
              </Box>
              <Box fontWeight="bold" flex={0.8}>
                Date Invited
              </Box>
              <Box w="48px" />
            </Flex>
            {response?.data?.invited_users
              .sort((a, b) => {
                const momentA = moment(a.invitation_issued)
                const momentB = moment(b.invitation_issued)
                if (momentA.isAfter(momentB)) return -1
                else if (momentB.isAfter(momentA)) return 1
                return 0
              })
              .map((user, i) => (
                <Flex
                  w="100%"
                  p="16px"
                  py="8px"
                  bg={i % 2 === 1 ? rowColor : 'transparent'}
                  key={`${user.email}-${i}`}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box flex={2}>{user.email}</Box>
                  <Box flex={0.9}>{user.role}</Box>
                  <Box flex={0.8}>
                    {moment(user.invitation_issued).format('L')}
                  </Box>
                  <DeletePendingInvitationPopover
                    pendingInviteDetails={user}
                    onDeleteInvitation={onDeleteInvitation}
                  >
                    <IconButton
                      isDisabled={isDeleting}
                      icon={<Icon as={AiOutlineDelete} />}
                      variant="ghost"
                    />
                  </DeletePendingInvitationPopover>
                </Flex>
              ))}
          </Box>
        )}
      </>
    )
  }

  return (
    <Modal size="2xl" onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p="32px">{modalBody}</ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

PendingInvitationsModal.propTypes = {
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default PendingInvitationsModal
