import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useAuth } from 'context/Auth'

// AXIOS RESPONSE INTERCEPTOR
const AxiosInterceptors = ({ children }) => {
  const { setIsAuthenticated } = useAuth()

  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      if (
        err.response &&
        (err.response?.status === 401 ||
          err.response?.data.message === '401 Unauthorized')
      ) {
        setIsAuthenticated(false)
      }
      return Promise.reject(err)
    }
  )
  return <>{children}</>
}

AxiosInterceptors.propTypes = {
  children: PropTypes.any,
}

export default AxiosInterceptors
