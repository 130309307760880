import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  useDisclosure,
  Icon,
  Flex,
  IconButton,
  Box,
  Spinner,
  Center,
  Tooltip,
} from '@chakra-ui/react'
import _upperFirst from 'lodash/upperFirst'

import { useApp } from 'context/App'
import { useAuth } from 'context/Auth'
import { ROLES } from 'context/Auth/constants'
import { useMutation, useQuery } from 'react-query'
import {
  deleteUser,
  editUserRole,
  getPendingInvitations,
  getUsers,
} from 'context/App/api'

import RegisterUserModal from './RegisterUserDrawer'
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineMail,
  AiOutlinePlusCircle,
  AiOutlineSearch,
} from 'react-icons/ai'
import DataTable from '../../Shared/DataTable'
import moment from 'moment'
import { TabHeading } from '..'
import DebouncedInput from 'components/Shared/Input/DebouncedInput'
import EditRolePopover from './EditRolePopover'
import DeleteUserPopover from './DeleteUserPopover'
import ScrollArea from 'components/Shared/ScrollArea'
import { FaPassport } from 'react-icons/fa'
import PendingInvitationsModal from './PendingInvitationsModal'

export const WELCOME_HEADER_TEXT = 'welcomeHeaderTextKey'
export const WELCOME_SUBHEADER_TEXT = 'welcomeSubHeaderTextKey'
export const WELCOME_BUTTON_TEXT = 'welcomeButtonTextKey'
export const USE_EXPERIMENTAL_PAGES = 'useExperimentalPages'

const Settings = () => {
  const [filter, setFilter] = useState(null)
  const [userData, setUserData] = useState([])
  const {
    onOpen: onInvitationsOpen,
    isOpen: isInvitationsOpen,
    onClose: onInvitationsClose,
  } = useDisclosure()
  const {
    isOpen: isRegisterUserOpen,
    onOpen: onRegisterUserOpen,
    onClose: onRegisterUserClose,
  } = useDisclosure()
  const { handleError } = useApp()
  const { hasRole, userEmail } = useAuth()

  const onViewPendingInvitations = () => onInvitationsOpen()

  const { data: pendingInvitationsResponse } = useQuery(
    ['getUserInvitations'],
    getPendingInvitations,
    {
      refetchOnWindowFocus: false,
      enabled: hasRole(ROLES.ADMIN),
    }
  )
  const hasPendingInvites =
    pendingInvitationsResponse?.data?.invited_users?.length > 0

  const {
    isLoading,
    isFetching: isFetchingUsers,
    data: response,
    refetch,
  } = useQuery(['getUsers'], getUsers, {
    refetchOnWindowFocus: false,
  })

  const { mutate: onDeleteUser, isLoading: isDeleting } = useMutation(
    deleteUser,
    {
      onSuccess: refetch,
      onError: handleError,
    }
  )

  const { mutate: handleEditRole, isLoading: isEditingRole } = useMutation(
    editUserRole,
    {
      onSuccess: refetch,
      onError: handleError,
    }
  )

  const isFetching = isFetchingUsers || isDeleting || isEditingRole

  // map and filter the data based on the text field
  useEffect(() => {
    setUserData(
      response?.data
        ?.map((user) => ({
          ...user,
          date_joined: moment(user.date_joined).format('L'),
          name: `${user.first_name} ${user.last_name}`,
          role: _upperFirst(user.role),
        }))
        .filter(
          (user) =>
            !filter ||
            user.name?.toLowerCase().includes(filter) ||
            user.username?.toLowerCase().includes(filter) ||
            user.role?.toLowerCase().includes(filter)
        )
    )
  }, [response, filter])

  const userColumns = [
    {
      Header: 'Email',
      accessor: 'username',
      Cell: (tableProps) => {
        if (tableProps?.row?.original?.external_user) {
          return (
            <Flex>
              {tableProps?.cell.value}
              <Tooltip label="This is an external user, invited from another tenant.">
                <Box>
                  <Icon as={FaPassport} w="24px" h="24px" ml="8px" />
                </Box>
              </Tooltip>
            </Flex>
          )
        }
        return tableProps?.cell.value
      },
    },
    { Header: 'Username', accessor: 'name' },
    { Header: 'Role', accessor: 'role' },
    { Header: 'Join Date', accessor: 'date_joined' },
  ]

  // only display this column if the user is an admin
  if (hasRole(ROLES.ADMIN)) {
    userColumns.push({
      Header: 'Actions',
      accessor: 'actions',
      Cell: (tableProps) => {
        // disabled actions on own user to prevent footgun actions
        const isCurrentUser = userEmail === tableProps?.row?.values?.username
        return (
          <Flex>
            <EditRolePopover
              onEditUserRole={handleEditRole}
              rowValues={tableProps?.row?.values}
            >
              <IconButton
                disabled={isFetching || isCurrentUser}
                variant="ghost"
                icon={<AiOutlineEdit />}
              />
            </EditRolePopover>
            <DeleteUserPopover
              onDeleteUser={onDeleteUser}
              rowValues={tableProps?.row?.original}
            >
              <IconButton
                disabled={isFetching || isCurrentUser}
                variant="ghost"
                icon={<AiOutlineDelete />}
                data-testid={`delete-user-button-${tableProps?.row?.values?.username}`}
              />
            </DeleteUserPopover>
          </Flex>
        )
      },
    })
  }

  const userTable = useMemo(() => (
    <DataTable
      overflow="hidden"
      initialState={{ sortBy: [{ id: 'username', desc: false }] }}
      columns={userColumns}
      data={userData}
    />
  ))

  return (
    <Flex flexDir="column" h="100vh">
      <TabHeading>User Management</TabHeading>
      <Flex justifyContent="space-between">
        <DebouncedInput
          maxW="400px"
          w="100%"
          icon={AiOutlineSearch}
          placeholder="Filter by name, username, or role"
          onDebounce={(val) => setFilter(val.toLowerCase?.())}
          mb="16px"
        />
        <Flex>
          {hasRole(ROLES.ADMIN) && hasPendingInvites && (
            <Button
              mb="16px"
              leftIcon={<Icon as={AiOutlineMail} />}
              onClick={onViewPendingInvitations}
              data-testid="add-user-button"
              variant="ghost"
              mr="8px"
            >
              Pending Invitations
            </Button>
          )}
          {hasRole(ROLES.STANDARD) && (
            <Button
              mb="16px"
              leftIcon={<Icon as={AiOutlinePlusCircle} />}
              onClick={onRegisterUserOpen}
              data-testid="add-user-button"
            >
              Add User
            </Button>
          )}
        </Flex>
      </Flex>
      {hasRole(ROLES.STANDARD) && (
        <RegisterUserModal
          refetchUsers={refetch}
          isOpen={isRegisterUserOpen}
          onClose={onRegisterUserClose}
        />
      )}
      {hasRole(ROLES.ADMIN) && (
        <PendingInvitationsModal
          isOpen={isInvitationsOpen}
          onClose={onInvitationsClose}
        />
      )}
      <ScrollArea>
        {userTable}
        {isLoading && (
          <Box pt="24px">
            <Center h="400px">
              <Spinner />
            </Center>
          </Box>
        )}
      </ScrollArea>
    </Flex>
  )
}

export default Settings
