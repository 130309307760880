import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Tooltip,
  Center,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import moment from 'moment'

const HealthStatusTick = ({ connection, ...remaining }) => {
  let bg, label, borderColor, healthColor
  const textColor = mode('gray.800', 'white')
  const tooltipBgColor = mode('white', 'gray.700')
  const tooltipBorderColor = mode('gray.100', 'gray.600')
  if (!connection) {
    borderColor = mode('gray.600', 'gray.500')
    bg = mode('white', 'gray.800')
    label = ''
  } else if (connection?.is_successful) {
    borderColor = mode('reachTeal.600', '#46CEB0')
    healthColor = mode('reachTeal.500', 'reachTeal.300')

    bg = mode('reachTeal.100', '#46CEB033')
    label = (
      <Box color={textColor}>
        <Box
          w="100%"
          border="1px solid"
          borderColor={tooltipBorderColor}
          p="4px 8px"
          my="4px"
          borderRadius="4px"
        >
          <Box fontWeight="light">Status</Box>
          <Box fontWeight="bold" color={healthColor}>
            Healthy
          </Box>
        </Box>
        <Box
          w="100%"
          border="1px solid"
          borderColor={tooltipBorderColor}
          p="4px 8px"
          my="4px"
          borderRadius="4px"
        >
          <Box fontWeight="light">Heartbeat Time (PST)</Box>
          <Box fontWeight="bold">
            {moment(connection?.connection_time).format('LLL')}
          </Box>
        </Box>
        <Box
          w="100%"
          border="1px solid"
          borderColor={tooltipBorderColor}
          p="4px 8px"
          my="4px"
          borderRadius="4px"
        >
          <Box fontWeight="light">Connection Type</Box>
          <Box fontWeight="bold">
            {connection?.credentials_type_used_verbose_name}
          </Box>
        </Box>
      </Box>
    )
  } else {
    borderColor = mode('#ff002baa', 'red.400')
    bg = mode('#ff002b22', '#9b5c2c33')
    label = (
      <Box color={textColor}>
        <Box
          w="100%"
          border="1px solid"
          borderColor={tooltipBorderColor}
          p="4px 8px"
          my="4px"
          borderRadius="4px"
        >
          <Box fontWeight="light">Status</Box>
          <Box fontWeight="bold" color={borderColor}>
            Error
          </Box>
        </Box>
        <Box
          w="100%"
          border="1px solid"
          borderColor={tooltipBorderColor}
          p="4px 8px"
          my="4px"
          borderRadius="4px"
        >
          <Box fontWeight="light">Heartbeat Time (PST)</Box>
          <Box fontWeight="bold">
            {moment(connection?.connection_time).format('LLL')}
          </Box>
        </Box>
        <Box
          w="100%"
          border="1px solid"
          borderColor={tooltipBorderColor}
          p="4px 8px"
          my="4px"
          borderRadius="4px"
        >
          <Box fontWeight="light">Connection Type</Box>
          <Box fontWeight="bold">
            {connection?.credentials_type_used_verbose_name}
          </Box>
        </Box>
        <Box
          w="100%"
          border="1px solid"
          borderColor={tooltipBorderColor}
          p="4px 8px"
          my="4px"
          borderRadius="4px"
        >
          <Box fontWeight="light">Details</Box>
          <Box>
            {connection?.reason ||
              connection?.error ||
              connection?.status_details ||
              'There was an error accessing the product.'}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Tooltip
      bg={tooltipBgColor}
      hasArrow
      label={label}
      p="1px 4px"
      borderRadius="6px"
    >
      <Center p="4px">
        <Box
          borderTop="0px"
          bg={bg}
          border="1px solid"
          borderColor={borderColor}
          borderRadius="50%"
          h="8px"
          w="8px"
          {...remaining}
        />
      </Center>
    </Tooltip>
  )
}

HealthStatusTick.propTypes = {
  connection: PropTypes.object,
}

export default HealthStatusTick
