import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Popover,
  PopoverTrigger,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  ButtonGroup,
  Button,
  Select,
  Text,
} from '@chakra-ui/react'
import { ROLES } from 'context/Auth/constants'

const EditRolePopover = ({ children, rowValues, onEditUserRole }) => {
  const [newRole, setNewRole] = useState(rowValues.role)
  const handleRoleChange = (e) => setNewRole(e.target.value)

  const handleConfirmEditRole = () => {
    onEditUserRole({ username: rowValues.username, role: newRole })
  }

  const initialFocusRef = React.useRef()
  return (
    <Popover initialFocusRef={initialFocusRef} placement="bottom">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Edit User Role
        </PopoverHeader>
        <PopoverArrow />
        <PopoverBody>
          <Text mb="16px">
            Grant and revoke role based access levels to your users.
          </Text>
          <Select onChange={handleRoleChange} value={newRole}>
            <option value={ROLES.ADMIN}>Admin</option>
            <option value={ROLES.STANDARD}>Standard User</option>
            <option value={ROLES.READ_ONLY}>Read-only</option>
          </Select>
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="flex-end"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button
              // disabled when there's no change from current state
              disabled={rowValues.role === newRole}
              onClick={handleConfirmEditRole}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

EditRolePopover.propTypes = {
  children: PropTypes.any,
  rowValues: PropTypes.object,
  onEditUserRole: PropTypes.func,
}

export default EditRolePopover
