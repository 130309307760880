import React from 'react'
import PropTypes from 'prop-types'
import {
  Flex,
  Box,
  Text,
  Select,
  Icon,
  Collapse,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { ERROR_COLOR, ERROR_COLOR_DARK } from 'assets/themeExtension'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const FormSelect = (props) => {
  const {
    mb,
    ml,
    mr,
    mt,
    m,
    label,
    name,
    id,
    options,
    errors,
    register,
    ...remaining
  } = props

  const error = errors[name]
  const errorColor = mode(ERROR_COLOR, ERROR_COLOR_DARK)

  return (
    <Box mb={mb} mt={mt} ml={ml} mr={mr} m={m}>
      <Text color={error ? errorColor : undefined} fontWeight="medium" mb="8px">
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={id || name}>{label}</label>
      </Text>
      <Select id={id || name} {...register(name, options)} {...remaining}>
        {options.map?.((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </Select>
      <Collapse in={error} animateOpacity>
        <Flex pt="2px" alignItems="center">
          <Icon mr="8px" color={errorColor} as={AiOutlineInfoCircle} />
          <Text fontSize="14px" fontWeight="light" color={errorColor}>
            {error?.message}
          </Text>
        </Flex>
      </Collapse>
    </Box>
  )
}

FormSelect.defaultProps = {
  options: {},
  errors: {},
  mb: '24px',
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  errors: PropTypes.object,
  register: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default FormSelect
