import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Heading,
  Button,
  Spacer,
  Center,
  Spinner,
  Flex,
  Icon,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { VscDebugDisconnect } from 'react-icons/vsc'
import HeaderDrawer from 'components/Shared/HeaderDrawer'
import { FiExternalLink } from 'react-icons/fi'
import { getApplicationsAuthMethods } from 'context/App/api'
import { useQuery } from 'react-query'
import {
  CONNECTION_METHODS,
  AUTH_METHODS_NAMES,
  AUTH_METHODS_PRIORITY,
} from '../constants'
import ProductDescription from '../../Product/ProductDescription'
import ConnectionSetupInstructions, {
  ProductSetupDocumentationLink,
} from 'components/Shared/Connections/SetupConnectionDrawer/ConnectionSetupInstructions'
import TextSwitch from 'components/Shared/TextSwitch'
import { PRODUCTS } from 'context/Programs/constants'
import ConnectionSetup from './ConnectionSetup'

/* 
The SetupConnectionDrawer does two things

1. Fetches the connection definitions
2. Determines which forms to show based off of that + the product
    - Snapshot upload is not returned from the list, we determine it by prodcut 
*/
const SetupConnectionDrawer = ({
  isOpen,
  onClose,
  onConnectionSuccess,
  onConnectionError,
  product,
  application,
}) => {
  const [connectionTypes, setConnectionTypes] = useState({})
  const [connection, setConnection] = useState({})
  const [selectionOptions, setSelectionOptions] = useState([])
  const [selectedAuthMethod, setSelectedAuthMethod] = useState({})
  const [submitButton, setSubmitButton] = useState(null)

  // fetch all connection definitions
  const { isLoading: isLoadingConnections } = useQuery(
    'getApplicationsAuthMethods',
    getApplicationsAuthMethods,
    {
      retry: true,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        const resData = res?.data

        if (!resData) return

        // TODO: get the backend to be configured properly
        // so we don't have to delete it in the FE
        delete resData.azure_ad.auth_methods.username_password

        // TODO: get the backend to be configured properly
        const addConnections = { ...resData }

        addConnections['local_ad'] = {
          verbose_name: 'Active Directory',
          auth_methods: {},
        }

        addConnections[PRODUCTS.paloAltoPrisma] = {
          verbose_name: 'Palo Alto Prisma Access',
          auth_methods: { none: { verbose_name: 'None', fields: {} } },
        }

        setConnectionTypes(addConnections)
      },
    }
  )

  // if we have auth methods for this product, set the connection
  useEffect(() => {
    const productResponse = connectionTypes[product]
    if (productResponse?.auth_methods) {
      setConnection(productResponse)
      const authMethods = Object.keys(productResponse?.auth_methods || {})
      const selectionOpts = [
        ...authMethods
          .sort((a, b) => AUTH_METHODS_PRIORITY[b] - AUTH_METHODS_PRIORITY[a])
          .map((authM) => ({
            value: authM,
            name: AUTH_METHODS_NAMES[authM],
          })),
      ]
      const shouldShowSnapshot = product === PRODUCTS.activeDirectory

      // authMethods does not include file snapshots, extra check for adding that
      // TODO: get the backend to be configured properly
      if (shouldShowSnapshot) {
        selectionOpts.push({
          value: CONNECTION_METHODS.FILE_SNAPSHOT,
          name: AUTH_METHODS_NAMES[CONNECTION_METHODS.FILE_SNAPSHOT],
        })
      }
      setSelectionOptions(selectionOpts)
      setSelectedAuthMethod(selectionOpts[0]?.value)
    }
  }, [product, connectionTypes])

  return (
    <HeaderDrawer
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      icon={false}
      header={
        <Flex mb="16px">
          <Icon w="30px" h="30px" mr="16px" as={VscDebugDisconnect} />
          {connection?.verbose_name
            ? `Integrate ${connection?.verbose_name}`
            : 'Loading Product . . .'}
        </Flex>
      }
      subHeader={<ProductDescription product={product} />}
      body={
        isLoadingConnections ? (
          <Center py="48px">
            <Spinner />
          </Center>
        ) : (
          <>
            {selectionOptions.length > 1 ? (
              <>
                <Heading size="sub3" mb="4px" ml="8px">
                  Connection Options
                </Heading>
                <TextSwitch
                  mb="16px"
                  value={selectedAuthMethod}
                  onClick={setSelectedAuthMethod}
                  options={selectionOptions}
                />
              </>
            ) : (
              <Heading size="sub" mb="16px">
                {selectionOptions[0]?.name}
              </Heading>
            )}

            <ConnectionSetupInstructions
              pb="48px"
              product={product}
              authMethod={selectedAuthMethod}
            />
            <ConnectionSetup
              pb="48px"
              product={product}
              setSubmitButton={setSubmitButton}
              onSuccess={onConnectionSuccess}
              onError={onConnectionError}
              authMethod={selectedAuthMethod}
              application={application}
            />
          </>
        )
      }
      footer={
        <>
          <ProductSetupDocumentationLink
            product={product}
            authMethod={selectedAuthMethod}
            isExternal
            icon={
              <Icon
                color={mode('button.500', 'button.200')}
                as={FiExternalLink}
              />
            }
            ml="16px"
          />
          <Spacer />
          {submitButton}
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </>
      }
    />
  )
}

SetupConnectionDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConnectionSuccess: PropTypes.func,
  onConnectionError: PropTypes.func,
  ppConnectionStatus: PropTypes.string,
  product: PropTypes.string,
  application: PropTypes.object,
}

export default SetupConnectionDrawer
