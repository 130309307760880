import React, {
  useState,
  useRef,
  useMemo,
  createContext,
  useContext,
  useEffect,
} from 'react'
import { useToast } from '@chakra-ui/toast'
import { USE_EXPERIMENTAL_PAGES } from 'components/Settings/UserManagementSettings'
import { useAuth } from 'context/Auth'
import { useQuery } from 'react-query'
import { getCustomerAttributes } from './api'
import { ONBOARDING_STATUSES, STATUS_REFETCH_INTERVAL } from './constants'

// centralized context for app related elements
// determines the initial connection status used in AppAuthenticated
// useful for utility functions like error handling, scrolling, global controls
const AppContext = createContext()

function useApp() {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error(`useApp must be used within a AppProvider`)
  }
  return context
}

function AppProvider(props) {
  const [applications, setApplications] = useState([])
  const [navState, setNavState] = useState({
    collapsed: localStorage.getItem('navPanelCollapsed') === 'true',
    hidden: false,
  })
  const [useExperimentalPages, setUseExperimentalPages] = useState(
    localStorage.getItem(USE_EXPERIMENTAL_PAGES) === 'true'
  )
  const [scrollBodyRef, setScrollBodyRef] = useState(useRef(null))

  const { accessToken, isAuthenticated } = useAuth()

  const { data: customerAttributesResponse } = useQuery(
    [accessToken, isAuthenticated, 'customerAttributes'],
    getCustomerAttributes,
    {
      enabled: Boolean(accessToken && isAuthenticated),
      refetchInterval: (response) => {
        if (
          response?.data?.onboarding_status === ONBOARDING_STATUSES.COMPLETED
        ) {
          return false
        }
        return STATUS_REFETCH_INTERVAL
      },
    }
  )
  const customerAttributes = customerAttributesResponse?.data
  const onboardingStatus = customerAttributesResponse?.data?.onboarding_status

  useEffect(async () => {
    // handle logout
    if (!isAuthenticated) {
      setApplications([])
      return
    }
  }, [accessToken, isAuthenticated])

  // utilty function for scrolling the main body to the top
  const scrollToTop = () =>
    scrollBodyRef?.current?.scrollTo({
      top: 0,
      behavior: 'auto',
    })

  // generic error handler that can be used by other functions for utility
  const toast = useToast()
  const handleError = (error, options) => {
    let message = 'There was an error performing this operation.'
    if (error) {
      message =
        error?.response?.data?.detail?.error ||
        error?.response?.data?.detail ||
        error?.response?.data?.details ||
        error?.response?.data?.message ||
        error.message ||
        error?.response?.data?.[0] ||
        'There was an error performing this action.'
    }

    toast({
      title: 'Error',
      description: message,
      status: 'error',
      duration: 6000,
      isClosable: true,
      position: 'top',
      ...options,
    })
  }

  const hideNavigationPanel = (hidden) => {
    setNavState((prev) => ({ ...prev, hidden }))
  }

  const value = useMemo(
    () => ({
      scrollToTop,
      handleError,
      navState,
      setNavState,
      hideNavigationPanel,
      scrollBodyRef,
      setScrollBodyRef,
      useExperimentalPages,
      setUseExperimentalPages,
      applications,
      customerAttributes,
      onboardingStatus,
    }),
    [
      navState,
      scrollBodyRef,
      useExperimentalPages,
      applications,
      customerAttributes,
      onboardingStatus,
    ]
  )
  return <AppContext.Provider value={value} {...props} />
}

export { AppProvider, useApp }
