import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Center, Spinner } from '@chakra-ui/react'

const EULA = ({ markdownText }) =>
  !markdownText ? (
    <Center minHeight="320px">
      <Spinner />
    </Center>
  ) : (
    <ReactMarkdown>{markdownText}</ReactMarkdown>
  )

EULA.propTypes = {
  markdownText: PropTypes.any,
}

export default EULA
