import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react'
import { useTable, useSortBy } from 'react-table'
import styled from '@emotion/styled'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

const DataTable = ({ data, columns, initialState, ...remaining }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState }, useSortBy)

  const StyledTable = styled(Table)`
    tr:nth-of-type(even) {
      background-color: ${useColorModeValue('#F0F1F5', '#2d2e33')};
    }
  `

  return (
    <StyledTable {...getTableProps()} {...remaining}>
      <Thead>
        {headerGroups.map((headerGroup, i) => (
          <Tr key={`headerRow-${i}`} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) => (
              <Th
                key={`headerGroup-${i}-${j}`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                isNumeric={column.isNumeric}
              >
                <Flex alignItems="center">
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <Icon
                        as={FaChevronUp}
                        ml="4px"
                        aria-label="sorted descending"
                      />
                    ) : (
                      <Icon
                        as={FaChevronDown}
                        ml="4px"
                        aria-label="sorted ascending"
                      />
                    )
                  ) : (
                    // so the format doesn't change when the icon is rendered
                    <Box w="12px" />
                  )}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <Tr key={`bodyRow-${i}`} {...row.getRowProps()}>
              {row.cells.map((cell, j) => (
                <Td
                  key={`bodyCell-${i}-${j}`}
                  {...cell.getCellProps()}
                  isNumeric={cell.column.isNumeric}
                >
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </StyledTable>
  )
}

DataTable.defaultProps = {
  data: [],
  columns: [],
  initialState: {},
}

DataTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  initialState: PropTypes.object,
}

export default DataTable
