import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'assets/fonts/GTWalsheimPro-Light.ttf'
import 'assets/fonts/GTWalsheimPro-Regular.ttf'
import 'assets/fonts/GTWalsheimPro-Medium.ttf'
import 'assets/fonts/GTWalsheimPro-Bold.ttf'
import { isDevEnv } from 'utils'

// AXIOS REQUEST INTERCEPTOR
axios.defaults.withCredentials = true
axios.interceptors.request.use(
  (request) => {
    if (request.url.charAt('0') === '/' && !isDevEnv()) {
      // this mapping should match setupProxy.js
      request.url = `https://app.reach.security${request.url}`
    }
    if (
      request.withCredentials &&
      request.url.includes('api') &&
      localStorage.getItem('accessToken') &&
      !(request.headers['authorization'] || request.headers['Authorization'])
    ) {
      request.headers['authorization'] = `Bearer ${localStorage.getItem(
        'accessToken'
      )}`
    }
    return request
  },
  (error) => Promise.reject(error)
)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
