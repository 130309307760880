import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react'
import moment from 'moment'
import { useAuth } from 'context/Auth'
import { useQuery } from 'react-query'
import { getProgramGroup, getProgramList } from './api'
import { getProgramExtraDetailsByType } from './utils'

// Programs provider contains security programs and related data
const OptimizationsContext = createContext()

function usePrograms() {
  const context = useContext(OptimizationsContext)
  if (!context) {
    throw new Error(`Optimizations must be used within a ProgramsProvider`)
  }
  return context
}

function ProgramsProvider(props) {
  const [selectedProgramGroup, setSelectedProgramGroup] = useState('')
  const [latestTimeStamp, setLatestListTimestamp] = useState(null)
  const [rollbackJobId, setRollbackJobId] = useState(null)
  const [securityPrograms, setSecurityPrograms] = useState([])
  const [selectedSecurityPrograms, setSelectedSecurityPrograms] = useState([])
  const [viewedProgramsModal, setViewedProgramsModal] = useState(false)
  const [viewedStatusModal, setViewedStatusModal] = useState(false)
  const { isAuthenticated, accessToken } = useAuth()

  // fetch the program list which contains group ids and timestamps
  const { data: programListResponse } = useQuery(
    ['program-list', accessToken, isAuthenticated],
    getProgramList,
    { enabled: Boolean(isAuthenticated) }
  )
  const programList = programListResponse?.data
  // programList but with unique groups only
  const programListGroups = programList?.length
    ? [
        ...programList
          .reduce((a, c) => {
            a.set(c.program_group, c)
            return a
          }, new Map())
          .values(),
      ]
    : []

  // fetch the latest program group after we've found it in the program list
  const { data: programGroupResponse, isLoading: isLoadingProgramGroups } =
    useQuery(
      [selectedProgramGroup, 'program-group'],
      () => getProgramGroup(selectedProgramGroup),
      {
        enabled: Boolean(selectedProgramGroup && isAuthenticated),
        refetchOnWindowFocus: false,
      }
    )

  // iterate through the list response until and set the latest
  useEffect(() => {
    if (!programList) return

    const latestListItem = programList.reduce((agg, listItem) => {
      if (moment(listItem.timestamp).isAfter(moment(agg.timestamp))) {
        return listItem
      }
      return agg
    }, programList[0])

    setSelectedProgramGroup(latestListItem?.program_group)
    setLatestListTimestamp(latestListItem?.timestamp)
  }, [programList])

  // enrich the program group with icons
  useEffect(() => {
    const enrichedPrograms = programGroupResponse?.data.map?.(
      (programItem) => ({
        ...programItem,
        ...getProgramExtraDetailsByType(programItem.program_type),
      })
    )
    setSecurityPrograms(enrichedPrograms)
  }, [programGroupResponse])

  const value = useMemo(
    () => ({
      programList,
      programListGroups,
      isLoadingProgramGroups,
      latestTimeStamp,
      securityPrograms,
      selectedSecurityPrograms,
      setSecurityPrograms,
      setSelectedSecurityPrograms,
      viewedProgramsModal,
      setViewedProgramsModal,
      viewedStatusModal,
      setViewedStatusModal,
      selectedProgramGroup,
      setSelectedProgramGroup,
      rollbackJobId,
      setRollbackJobId,
    }),
    [
      isLoadingProgramGroups,
      latestTimeStamp,
      securityPrograms,
      selectedSecurityPrograms,
      viewedProgramsModal,
      viewedStatusModal,
      selectedProgramGroup,
      rollbackJobId,
    ]
  )

  return <OptimizationsContext.Provider value={value} {...props} />
}

export { ProgramsProvider, usePrograms }
