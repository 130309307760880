import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Icon,
  Heading,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'
import UserManagementSettings from './UserManagementSettings'
import UserProfileSettings from './ProfileSettings'
import ConnectionSettings from './ConnectionSettings'
import SSOSettings from './SSO'
// import SuperUserSettings from './SuperUserSettings'
import { AiOutlineCrown } from 'react-icons/ai'
import EulaModal from 'components/Shared/EulaModal'
import { useAuth } from 'context/Auth'
import { ROLES } from 'context/Auth/constants'

const TAB_ROUTES = {
  USER_PROFILE: '/settings/profile',
  USER_MANAGEMENT: '/settings/user-management',
  CONNECTIONS: '/settings/connections',
  SSO: '/settings/single-sign-on',
}

const ROUTE_INDEX = [
  TAB_ROUTES.USER_PROFILE,
  TAB_ROUTES.USER_MANAGEMENT,
  TAB_ROUTES.SSO,
  TAB_ROUTES.CONNECTIONS,
]

export const TabHeading = ({ ...props }) => (
  <Heading size="lg" pb="12px" mt="28px" mb="16px" {...props} />
)

const CustomTab = ({ icon, children, ...remaining }) => (
  <Tab
    justifyContent="flex-start"
    style={{ borderCollapse: 'collapse' }}
    borderBottom="1px solid"
    px="24px"
    py="16px"
    w="100%"
    color={mode('gray.600', 'gray.300')}
    _selected={{
      bg: mode('white', 'gray.800'),
      fontWeight: 'medium',
      color: mode('gray.900', 'white'),
    }}
    {...remaining}
    borderColor={mode('gray.200', 'gray.700')}
  >
    {icon && <Icon as={icon} mr="16px" />} {children}
  </Tab>
)
CustomTab.propTypes = {
  icon: PropTypes.any,
  children: PropTypes.any,
}

const Settings = () => {
  const history = useHistory()
  const { hasRole } = useAuth()

  const {
    onOpen: onOpenEula,
    onClose: onCloseEula,
    isOpen: isEulaOpen,
  } = useDisclosure()
  const [tabIndex, setTabIndex] = useState(
    ROUTE_INDEX.findIndex((el) => el === history.location.pathname) >= 0
      ? ROUTE_INDEX.findIndex((el) => el === history.location.pathname)
      : 0
  )

  const handleTabChange = (index) => {
    const indexRoute = ROUTE_INDEX[index]
    history.push(indexRoute || 'settings')
    setTabIndex(index)
  }

  return (
    <>
      <EulaModal
        isOpen={isEulaOpen}
        onOpen={onOpenEula}
        onClose={onCloseEula}
      />
      <Tabs
        isLazy
        index={tabIndex}
        onChange={handleTabChange}
        orientation="vertical"
        variant="unstyled"
        colorScheme="button"
      >
        <TabList
          borderRight="1px solid"
          borderColor={mode('gray.200', 'gray.700')}
          bg={mode('gray.100', 'gray.900')}
          h="100vh"
          pt="32px"
          w="320px"
        >
          <Heading size="sub" ml="24px" mb="24px">
            Settings
          </Heading>
          <CustomTab data-testid="profile-settings-tab" borderTop="1px solid">
            Profile Settings
          </CustomTab>
          <CustomTab data-testid="user-management-tab">
            User Management
          </CustomTab>
          {hasRole(ROLES.ADMIN) && (
            <CustomTab data-testid="single-sign-on-tab">
              Single Sign On
            </CustomTab>
          )}
          {hasRole(ROLES.STANDARD) && (
            <CustomTab data-testid="manage-connections-tab">
              Manage Connections
            </CustomTab>
          )}
          {/* {hasRole(ROLES.SUPER_USER) && (
            <CustomTab data-testid="super-user-tab">
              Super User Settings
            </CustomTab>
          )} */}
        </TabList>
        <TabPanels px="48px">
          <TabPanel p="0">
            <TabHeading>Profile Settings</TabHeading>
            <UserProfileSettings onOpenEula={onOpenEula} />
          </TabPanel>
          <TabPanel p="0">
            <UserManagementSettings />
          </TabPanel>
          {hasRole(ROLES.ADMIN) && (
            <TabPanel p="0">
              <SSOSettings />
            </TabPanel>
          )}
          <TabPanel p="0" h="100vh" overflow="auto">
            <TabHeading>Manage Connections</TabHeading>
            <ConnectionSettings onOpenEula={onOpenEula} />
          </TabPanel>
          <TabPanel p="0">
            <TabHeading>
              Super User <Icon mb="8px" w="32px" h="32px" as={AiOutlineCrown} />
            </TabHeading>
            {/* <SuperUserSettings /> */}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default Settings
