import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, useToast } from '@chakra-ui/react'
import { AiOutlineCopy } from 'react-icons/ai'

const CopyButton = ({ content, ...remainingProps }) => {
  const toast = useToast()

  return (
    <Button
      onClick={() => {
        navigator.clipboard.writeText(content)
        toast({
          description: 'Content copied to your clipboard.',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'top',
        })
      }}
      leftIcon={<Icon as={AiOutlineCopy} />}
      {...remainingProps}
    >
      Copy
    </Button>
  )
}

CopyButton.propTypes = {
  content: PropTypes.any,
}

export default CopyButton
