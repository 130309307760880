import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, useToast, Icon, useDisclosure } from '@chakra-ui/react'
import { useMutation } from 'react-query'
import axios from 'axios'
import { useAuth } from 'context/Auth'
import { useApp } from 'context/App'
import { RiLockPasswordLine } from 'react-icons/ri'
import MFASetupDrawer from './MFASetupDrawer'

const MFAPanel = ({ onSetupBegin, onSetupComplete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [base64Image, setBase64Image] = useState('')
  const [mfaSecret, setMfaSecret] = useState('')
  const [mfaConfirmationCode, setMfaConfirmationCode] = useState('')

  const { setMfaEnabled } = useAuth()
  const { handleError } = useApp()

  const toast = useToast()

  // GET --- get the QR code and totp secret ---
  const { mutate: fetchMfaCode, isLoading: isMfaFetchLoading } = useMutation(
    () => {
      return axios.get('/api/totp-mfa/register').catch((e) => Promise.reject(e))
    },
    {
      onError: (e) => {
        handleError(e)
        if (
          e?.response?.status === 400 &&
          e?.response?.data?.details?.includes('already have an authenticator')
        ) {
          setMfaEnabled(true)
        }
      },
      onSuccess: (response) => {
        onSetupBegin?.()
        setBase64Image(response?.data?.qrcode_b64)
        setMfaSecret(response?.data?.totp_secret)
        onOpen(true)
      },
    }
  )

  // POST --- confirm the 6 digit code
  const { mutate: verifyMfaCode, isLoading: isConfirmationLoading } =
    useMutation(
      (body) => {
        return axios
          .post('/api/totp-mfa/register', body)
          .catch((e) => Promise.reject(e))
      },
      {
        onError: handleError,
        onSuccess: async () => {
          onSetupComplete?.()
          toast({
            title: 'MFA Setup Success!',
            description:
              'Multi-factor authentication is now enabled for this user.',
            status: 'success',
            duration: 4000,
            isClosable: true,
            position: 'top',
          })
        },
      }
    )

  const handleMfaConfirmationCodeChange = async (val) => {
    setMfaConfirmationCode(val)
    if (val.length === 6) {
      verifyMfaCode({ totp_token: val, totp_secret: mfaSecret })
    }
  }

  return (
    <>
      <Button
        disabled={isConfirmationLoading}
        isLoading={isConfirmationLoading || isMfaFetchLoading}
        loadingText="Generating QR Code"
        onClick={fetchMfaCode}
        rightIcon={<Icon as={RiLockPasswordLine} />}
      >
        Set up MFA
      </Button>
      <MFASetupDrawer
        mfaSecret={mfaSecret}
        mfaConfirmationCode={mfaConfirmationCode}
        onMfaConfirmationCodeChange={handleMfaConfirmationCodeChange}
        base64Image={base64Image}
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isMfaFetchLoading}
      />
    </>
  )
}

MFAPanel.propTypes = {
  mfaConfirmationCode: PropTypes.string,
  mfaConfirmationStatus: PropTypes.string,
  handleMfaConfirmationCodeChange: PropTypes.func,
  onSetupComplete: PropTypes.func,
  onSetupBegin: PropTypes.func,
}

export default MFAPanel
