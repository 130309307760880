import React from 'react'
import PropTypes from 'prop-types'
import {
  Popover,
  PopoverTrigger,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  ButtonGroup,
  Button,
  Text,
} from '@chakra-ui/react'
import { getProductName } from 'components/Shared/Product/ProductDetails'

const ConfirmDeletePopover = ({
  children,
  product,
  onConfirm,
  isLoading,
  isSuccess,
}) => {
  const initialFocusRef = React.useRef()

  return (
    <Popover initialFocusRef={initialFocusRef} placement="bottom">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Are you sure?
        </PopoverHeader>
        <PopoverArrow />
        <PopoverBody>
          <Text>
            You are about to delete previous standalone connections so that you
            can establish a parent connection to {getProductName(product)}.
          </Text>
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="flex-end"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button
              colorScheme="red"
              onClick={onConfirm}
              isLoading={isLoading}
              disabled={isSuccess}
              loadingText="Removing Connections"
            >
              Yes, Remove connections
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

ConfirmDeletePopover.propTypes = {
  onConfirm: PropTypes.func,
  product: PropTypes.string,
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
}

export default ConfirmDeletePopover
