import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerBody,
  Icon,
  Flex,
  Text,
  Center,
  useColorModeValue as mode,
  DrawerFooter,
  Heading,
} from '@chakra-ui/react'
import { FiKey } from 'react-icons/fi'
import ScrollArea from './ScrollArea'

const HeaderDrawer = ({
  icon,
  body,
  footer,
  header,
  subHeader,
  isOpen,
  onClose,
  size,
  initialFocus,
}) => {
  const bodyBgColor = mode('gray.50', 'gray.700')
  const headerBgColor = mode('brand.primary', 'brand.primaryDark')

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      initialFocusRef={initialFocus}
    >
      <DrawerOverlay />
      <Box borderRadius="20px" overflow="hidden">
        <DrawerContent
          // borderBottomLeftRadius="40px"
          borderTopLeftRadius="40px"
          overflow="hidden"
          bg={bodyBgColor}
        >
          <DrawerCloseButton color="white" />
          <Flex
            px="32px"
            py="40px"
            bg={headerBgColor}
            alignItems={subHeader ? 'flex-start' : 'center'}
          >
            {icon && (
              <Center
                flexShrink="0"
                w="56px"
                h="56px"
                borderRadius="50%"
                mr="16px"
              >
                <Icon color="white" as={icon} w="32px" h="32px" />
              </Center>
            )}
            <Box>
              <Heading color="white" size="md" mb="4px">
                {header}
              </Heading>
              <Text color="white" fontWeight="light" lineHeight="1.2">
                {subHeader}
              </Text>
            </Box>
          </Flex>
          <Box
            h="20px"
            w="100%"
            bg={bodyBgColor}
            borderTopRadius="50%"
            position="relative"
            top="-10px"
          />
          <DrawerBody bg={bodyBgColor} pt="0px" px="32px">
            <ScrollArea>{body}</ScrollArea>
          </DrawerBody>
          <DrawerFooter>{footer}</DrawerFooter>
        </DrawerContent>
      </Box>
    </Drawer>
  )
}

HeaderDrawer.defaultProps = {
  icon: FiKey,
  size: 'md',
}

HeaderDrawer.propTypes = {
  icon: PropTypes.any,
  body: PropTypes.any,
  footer: PropTypes.any,
  header: PropTypes.any,
  subHeader: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.string,
  initialFocus: PropTypes.any,
}

export default HeaderDrawer
