import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box, Button } from '@chakra-ui/react'
import FormInput from 'components/Shared/Form/FormInput'
import { useMutation } from 'react-query'
import { postConnectionCredentials } from 'context/App/api'
import { CONNECTION_METHODS } from '../../constants'

const ClientSecretForm = ({ onSuccess, onError, product, setSubmitButton }) => {
  // submission request
  const {
    isLoading,
    isSuccess,
    mutate: postCredentials,
  } = useMutation(postConnectionCredentials, {
    onSuccess: () => onSuccess(product),
    onError,
  })

  // form validation
  const formSchema = yup.object().shape({
    client_id: yup.string().required('Client ID is required'),
    client_secret: yup.string().required('Client ID is required'),
    url: yup.string().required('Client ID is required'),
  })

  // react-hook-form instantiation and options
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  })

  const onSubmit = (credentials) => {
    postCredentials({
      product,
      authMethod: CONNECTION_METHODS.CLIENT_SECRET,
      credentials,
    })
  }

  useEffect(() => {
    setSubmitButton(
      <Button
        mr="8px"
        isLoading={isLoading}
        loadingText="Submitting"
        disabled={disableInteraction}
        onClick={handleSubmit(onSubmit)}
      >
        Submit
      </Button>
    )
    return () => setSubmitButton(null)
  }, [])

  const disableInteraction = isLoading || isSuccess

  return (
    <Box px="16px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          name="client_id"
          label="Client ID"
          disabled={disableInteraction}
          register={register}
          errors={errors}
        />
        <FormInput
          name="client_secret"
          label="Client Secret"
          disabled={disableInteraction}
          register={register}
          errors={errors}
        />
        <FormInput
          name="url"
          label="URL"
          disabled={disableInteraction}
          register={register}
          errors={errors}
        />
        {/* allows form submission with enter key */}
        <Button visibility="hidden" type="submit" />
      </form>
    </Box>
  )
}

ClientSecretForm.defaultProps = {
  onSuccess: () => console.log('Success'),
  onError: (e) => console.log(e),
}

ClientSecretForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  product: PropTypes.string,
  setSubmitButton: PropTypes.func,
}

export default ClientSecretForm
