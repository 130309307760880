import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Icon, Center, useToast } from '@chakra-ui/react'
import { FiExternalLink } from 'react-icons/fi'
import { useMutation } from 'react-query'
import { getDelegateToken } from 'context/App/api'
import { AiOutlineCopy } from 'react-icons/ai'
import { PRODUCTS } from 'context/Programs/constants'

const OAUTH_BASE_URL_MAP = {
  [PRODUCTS.azureActiveDirectory]:
    'https://login.microsoftonline.com/common/adminconsent?client_id=f546ae82-a1f9-45f1-9250-53116db3e306',
  [PRODUCTS.microsoft]:
    'https://login.microsoftonline.com/common/adminconsent?client_id=52f3e8c1-f4f9-487c-b4ca-43f8efd72e48',
}

const OAuthForm = ({ product }) => {
  const toast = useToast()
  const [shouldCopyLink, setShouldCopyLink] = useState(false)
  const {
    isLoading: isLoadingDelegateToken,
    mutate: fetchDelegateToken,
    data: response,
  } = useMutation(getDelegateToken)

  useEffect(() => {
    const accessToken = response?.data?.access
    const stringifiedState = JSON.stringify({
      accessToken,
      postUrl: 'https://app.reach.security/api/credentials/delegate',
      application: product,
    })
    if (accessToken) {
      const link = `${OAUTH_BASE_URL_MAP[product]}&state=${stringifiedState}`
      if (shouldCopyLink) {
        navigator.clipboard.writeText(link)
        toast({
          title: 'Link copied to clipboard.',
          status: 'info',
          duration: 4000,
          isClosable: true,
          position: 'top',
        })
      } else {
        window.open(link, 'Reach OAuth', [
          'popup',
          'width=440px',
          'height=600px',
        ])
      }
    }
  }, [response?.data?.access])

  const handleOpenLink = () => {
    setShouldCopyLink(false)
    fetchDelegateToken()
  }

  const handleCopyLink = () => {
    setShouldCopyLink(true)
    fetchDelegateToken()
  }

  return (
    <Box px="16px">
      <Center mb="8px">
        <Button
          width="160px"
          size="lg"
          loadingText="Connect"
          rightIcon={<Icon as={FiExternalLink} />}
          onClick={handleOpenLink}
          isLoading={isLoadingDelegateToken && !shouldCopyLink}
          disabled={isLoadingDelegateToken}
        >
          Connect
        </Button>
      </Center>
      <Center>
        <Button
          width="160px"
          size="lg"
          loadingText="Copying Link"
          rightIcon={<Icon as={AiOutlineCopy} />}
          onClick={handleCopyLink}
          isLoading={isLoadingDelegateToken && shouldCopyLink}
          disabled={isLoadingDelegateToken}
          variant="ghost"
        >
          Copy Link
        </Button>
      </Center>
    </Box>
  )
}

OAuthForm.defaultProps = {
  onSuccess: () => console.log('Success'),
  onError: (e) => console.log(e),
}

OAuthForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  product: PropTypes.string,
}

export default OAuthForm
