import React, { useState, useEffect, useMemo } from 'react'
import jwtDecode from 'jwt-decode'
import { postLogout, getSsoConfigurations } from './api'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { getCustomerAttributes } from 'context/App/api'

// authentication provider
// logging in/out, RBAC, SSO, jwt data

const AuthContext = React.createContext()

function useAuth() {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

const initialRegistrationValues = {
  email: '',
  emailSent: false,
  token: '',
  accessCode: undefined,
  authToken: undefined,
  accessCodeRegistered: false,
  accountCreated: false,
  mfaConfirmationCode: '',
  username: '',
}

function AuthProvider(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const [username, setUsername] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [tenant, setTenant] = useState('')
  const [scopes, setScopes] = useState([])
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken')
  )
  const [accessTokenData, setAccessTokenData] = useState(undefined)
  const [mfaEnabled, setMfaEnabled] = useState(false)
  const [mfaVerified, setMfaVerified] = useState(false)

  const [registrationDetails, setRegistrationDetails] = useState(
    initialRegistrationValues
  )

  const queryClient = useQueryClient()
  const history = useHistory()

  const handleCheckAccessTokenError = () => {
    clearAuthValues()
  }

  // fetch customer attributes to check accessToken validity
  const { mutate: handleCheckAccessToken } = useMutation(
    getCustomerAttributes,
    {
      onSuccess: () => setIsAuthenticated(true),
      onError: handleCheckAccessTokenError,
    }
  )

  const { data, refetch: refetchSsoConfigurations } = useQuery(
    ['get-sso-configuration', accessToken],
    getSsoConfigurations,
    { refetchOnWindowFocus: false, enabled: !!isAuthenticated }
  )
  const ssoConfiguration = data?.data?.response?.configuration
  const isSsoEnabled = !!ssoConfiguration

  // on initialization, use the access token if one is available
  // otherwise we are in a logged out state
  useEffect(async () => {
    if (accessToken) {
      handleCheckAccessToken()
    } else {
      setIsAuthenticated(false)
    }
  }, [])

  // utility used in below useEffect
  const tryGetUserData = (tokenData) => {
    if (tokenData?.signed_data?.data) {
      const { data } = tokenData?.signed_data
      const splitEmail = data?.user.split?.(/[+@.\-_]/)
      !username && setUserEmail(data?.user)
      !username && splitEmail.length > 0 && setUsername(splitEmail[0])
      !tenant && setTenant(data?.tenant)
    }
  }

  // when we receive tokens, extract data from the access token
  useEffect(() => {
    if (accessToken) {
      if (localStorage.getItem('accessToken') !== accessToken) {
        // axios middleware uses the localstorage accessToken for requests
        localStorage.setItem('accessToken', accessToken)
      }
      try {
        const tokenData = jwtDecode(accessToken)
        const scopes = tokenData?.scope?.split?.(' ')
        setAccessTokenData(tokenData)
        tryGetUserData(tokenData)
        if (scopes?.length > 0) {
          setScopes(scopes)
        }
      } catch (e) {
        console.error('invalid jwt token!', e)
      }
    }
  }, [accessToken])

  const setRegistrationDetailsValues = (details) =>
    setRegistrationDetails((val) => ({ ...val, ...details }))

  const clearAuthValues = () => {
    setUsername('')
    setAccessToken('')
    setMfaEnabled(false)
    setIsAuthenticated(false)
    localStorage.removeItem('accessToken')
    setRegistrationDetails(initialRegistrationValues)
    queryClient.clear()
  }
  const handleLogout = async () => {
    try {
      await postLogout(accessToken)
    } catch (e) {
      console.log(e)
    }
    clearAuthValues()
    history.push('/')
  }

  // handy utility function
  const hasRole = (role) => {
    if (!scopes || !Array.isArray(scopes) || scopes.length === 0) {
      return false
    }
    return scopes.includes?.(role)
  }

  const value = useMemo(
    () => ({
      scopes,
      hasRole,
      accessToken,
      accessTokenData,
      setAccessToken,
      mfaEnabled,
      setMfaEnabled,
      mfaVerified,
      setMfaVerified,
      ssoConfiguration,
      refetchSsoConfigurations,
      isSsoEnabled,
      isAuthenticated,
      setIsAuthenticated,
      username,
      userEmail,
      tenant,
      setUsername,
      registrationDetails,
      setRegistrationDetailsValues,
      logout: handleLogout,
      checkAccessToken: handleCheckAccessToken,
    }),
    [
      isAuthenticated,
      username,
      userEmail,
      tenant,
      accessToken,
      accessTokenData,
      mfaEnabled,
      mfaVerified,
      registrationDetails,
      isSsoEnabled,
      ssoConfiguration,
    ]
  )

  return <AuthContext.Provider value={value} {...props} />
}

export { AuthProvider, useAuth }
