import { IoFishOutline, IoShieldOutline } from 'react-icons/io5'
import { FiEye, FiTarget } from 'react-icons/fi'
import { AiOutlineQuestion } from 'react-icons/ai'
import { RiSkull2Line } from 'react-icons/ri'

export const getProgramExtraDetailsByType = (type) => {
  switch (type) {
    case 'most_attacked_phishing':
    case 'Most Attacked: Phishing':
      return {
        icon: IoFishOutline,
        color: '#4362d7',
        favorite: true,
        category: 'phishing',
      }
    case 'most_attacked_malware':
    case 'Most Attacked: Malware':
      return {
        icon: RiSkull2Line,
        color: '#4362d7',
        favorite: true,
        category: 'malware',
      }
    case 'baseline':
    case 'Baseline Configuration':
      return {
        icon: IoShieldOutline,
        color: '#4362d7',
        favorite: true,
        category: 'overall',
      }
    case 'visibility':
    case 'Visibility':
      return {
        icon: FiEye,
        color: '#4362d7',
        favorite: true,
        category: 'visibility',
      }
    case 'most_attacked_overall':
    case 'Most Attacked: Overall':
      return {
        icon: FiTarget,
        color: '#4362d7',
        favorite: true,
        category: 'overall',
      }
    default:
      return {
        icon: AiOutlineQuestion,
        color: '#4362d7',
        favorite: false,
        category: '',
      }
  }
}
