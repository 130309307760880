import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box, Button, useToast } from '@chakra-ui/react'
import FormInput from 'components/Shared/Form/FormInput'
import { useMutation } from 'react-query'
import { postConnectionCredentials } from 'context/App/api'
import B from 'components/Shared/B'
import { CONNECTION_METHODS } from '../../constants'

const UsernamePasswordForm = ({
  onSuccess,
  product,
  onError,
  setSubmitButton,
}) => {
  const toast = useToast()

  // submission request
  const {
    isLoading,
    isSuccess,
    mutate: postCredentials,
  } = useMutation(postConnectionCredentials, {
    onSuccess: () => onSuccess(),
    onError: (e) => {
      if (e.response?.status !== 500) {
        onError(e)
      } else {
        // TODO -- the backend should fix the 500 response on invalid credentials
        // remove this special handling when the fix is in
        toast({
          title: 'Credentials Failed',
          description:
            'There was an issue submitting your credentials. Please double check your inputs.',
          status: 'error',
          duration: 6000,
          isClosable: true,
          position: 'top',
        })
      }
    },
  })

  // form validation
  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email('Email is not a valid format')
      .required('Email is required'),
    password: yup.string().required('Password is required'),
    totp_secret: yup.string(),
  })

  // react-hook-form instantiation and options
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  })

  const onSubmit = ({ email, password, totp_secret }) => {
    postCredentials({
      product,
      authMethod: CONNECTION_METHODS.USERNAME_PASSWORD,
      credentials: {
        username: email,
        password,
        totp_secret: totp_secret || undefined,
      },
    })
  }

  const disableInteraction = isLoading || isSuccess

  useEffect(() => {
    setSubmitButton(
      <Button
        mr="8px"
        isLoading={isLoading}
        loadingText="Submitting"
        disabled={disableInteraction}
        onClick={handleSubmit(onSubmit)}
      >
        Submit
      </Button>
    )
    return () => setSubmitButton(null)
  }, [isLoading, disableInteraction])

  return (
    <Box px="16px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          name="email"
          label="Read-only account username"
          disabled={disableInteraction}
          register={register}
          errors={errors}
        />
        <FormInput
          name="password"
          label="Read-only account password"
          type="password"
          disabled={disableInteraction}
          register={register}
          errors={errors}
        />
        <FormInput
          name="totp_secret"
          label={
            <Box>
              MFA Secret{' '}
              <B fontWeight="light" opacity=".65">
                optional
              </B>
            </Box>
          }
          disabled={disableInteraction}
          register={register}
          errors={errors}
        />
        {/* allows form submission with enter key */}
        <Button visibility="hidden" type="submit" />
      </form>
    </Box>
  )
}

UsernamePasswordForm.defaultProps = {
  onSuccess: () => console.log('Success'),
  onError: (e) => console.log(e),
}

UsernamePasswordForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  product: PropTypes.string,
  setSubmitButton: PropTypes.func,
}

export default UsernamePasswordForm
