import React from 'react'
import PropTypes from 'prop-types'
import { Box, useColorModeValue } from '@chakra-ui/react'

const Card = ({ children, borderColor, ...remaining }) => {
  const background = useColorModeValue('brand.light', 'gray.800')

  return (
    <Box
      bg={background}
      padding="32px"
      borderRadius="24px"
      border="1px solid"
      borderColor={borderColor || useColorModeValue('#DDE0E8DD', '#656F9599')}
      {...remaining}
    >
      {children}
    </Box>
  )
}

Card.propTypes = {
  borderColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Card
