import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import MotionBox from '../MotionBox'
import { FOCUSED_COLOR, FOCUSED_COLOR_DARK } from 'assets/themeExtension'

const DebouncedInput = ({
  mb,
  ml,
  mr,
  mt,
  m,
  w,
  width,
  maxW,
  maxWidth,
  onChange,
  onDebounce,
  debounceTime,
  onBlur,
  onFocus,
  readOnly,
  icon,
  ...remaining
}) => {
  const [debounceFn] = useState(() =>
    debounce((value) => onDebounce(value), debounceTime)
  )
  const [focused, setFocused] = useState(false)

  const activeUnderlineBgColor = mode(FOCUSED_COLOR, FOCUSED_COLOR_DARK)
  let underlineBgColor = mode('#ffffff00', '#2220')
  if (focused && !readOnly) {
    underlineBgColor = activeUnderlineBgColor
  }

  return (
    <Box
      position="relative"
      w={w || width}
      maxW={maxW || maxWidth}
      mb={mb}
      ml={ml}
      mr={mr}
      mt={mt}
      m={m}
    >
      <InputGroup>
        {icon && (
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
          >
            <Icon as={icon} />
          </InputLeftElement>
        )}
        <Input
          readOnly={readOnly}
          opacity={readOnly ? 0.5 : 1}
          onChange={(e) => {
            onChange?.(e)
            debounceFn(e?.target?.value)
          }}
          onFocus={(e) => {
            onFocus?.(e)
            setFocused(true)
          }}
          onBlur={(e) => {
            onBlur?.(e)
            setFocused(false)
          }}
          {...remaining}
        />
      </InputGroup>
      <MotionBox
        transition={{ duration: 0.2 }}
        animate={{ background: underlineBgColor }}
        initial={{ background: '#ffffff00' }}
        h="6px"
        borderBottomRadius="3px"
        position="absolute"
        transform="perspective(800px) rotate3d(-1, 0, 0, 45deg) scaleX(.997) translateY(1px)"
        bottom="0"
        left="0"
        w="100%"
      />
    </Box>
  )
}

DebouncedInput.defaultProps = {
  debounceTime: 500,
}

DebouncedInput.propTypes = {
  icon: PropTypes.any,
  onDebounce: PropTypes.func,
  debounceTime: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  readOnly: PropTypes.bool,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxW: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default DebouncedInput
