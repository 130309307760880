import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  getUserAgreements,
  getUserAgreementsList,
  postSignUserAgreement,
} from 'context/App/api'
import {
  Box,
  Button,
  Center,
  Checkbox,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
  Heading,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react'
import { useMutation, useQuery } from 'react-query'
import EULA from './EULA'

const EulaModal = ({ isOpen, onClose }) => {
  const [shouldSendEULAEmail, setShouldSendEULAEmail] = useState(false)
  const [isEULAChecked, setIsEULAChecked] = useState(false)
  const [isEULASigned, setIsEULASigned] = useState(false)
  const toast = useToast()

  // fetches the most recent version of the EULA
  const {
    isSuccess: isAgreementSuccess,
    data: agreementResponse,
    isLoading: isLoadingEula,
  } = useQuery('getUserAgreements', getUserAgreements, {
    refetchOnWindowFocus: false,
  })
  const agreement = agreementResponse?.data
  // fetches previously signed versions (if any)
  const { isSuccess: isListSuccess, data: agreementsListResponse } = useQuery(
    'getUserAgreementsList',
    getUserAgreementsList,
    { refetchOnWindowFocus: false }
  )

  // upon the getUserAgreements and getUserAgreementsList completion
  // check to see if we already signed this version
  useEffect(() => {
    const foundSignedVersion = agreementsListResponse?.data?.find?.(
      (agreement) => agreement?.version === agreementResponse?.version
    )
    if (foundSignedVersion) {
      setIsEULASigned(true)
    }
  }, [isAgreementSuccess, isListSuccess])

  const handleSignEulaSuccess = () => {
    setIsEULASigned(true)
    onClose()
    toast({
      title: 'EULA Signed',
      description: 'You have agreed to the Reach terms & conditions',
      status: 'success',
      duration: 4000,
      isClosable: true,
      position: 'top',
    })
  }
  // for actually signing
  const { mutate: signEula, isLoading } = useMutation(postSignUserAgreement, {
    onSuccess: handleSignEulaSuccess,
  })

  const handleSignAgreement = () => {
    if (!isEULASigned && isEULAChecked) {
      signEula({ version: agreement?.version, sendEmail: shouldSendEULAEmail })
    }
    onClose()
  }

  const initialRef = React.useRef()

  return (
    <Modal
      initialFocusRef={initialRef}
      size="6xl"
      isOpen={isOpen}
      onClose={onClose}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading px="16px" pt="16px">
            Reach Terms & Conditions
          </Heading>
          {isLoadingEula ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <>
              <Box
                position="relative"
                p="16px"
                overflow="auto"
                mb="24px"
                flex={1}
                borderRadius="8px"
              >
                <EULA markdownText={agreement?.text} />
              </Box>
              <Box pl="32px">
                <Checkbox
                  size="lg"
                  isChecked={isEULAChecked || isEULASigned}
                  disabled={isEULASigned}
                  onChange={(e) => {
                    setIsEULAChecked(e.target.checked)
                  }}
                  mb="8px"
                  colorScheme="button"
                >
                  I agree to the above terms and conditions
                </Checkbox>
                <Checkbox
                  display="block"
                  size="lg"
                  isChecked={shouldSendEULAEmail}
                  disabled={isEULASigned}
                  onChange={(e) => {
                    if (!isEULASigned) setShouldSendEULAEmail(e.target.checked)
                  }}
                  mb="40px"
                  colorScheme="button"
                >
                  Email me a copy of the EULA
                </Checkbox>
              </Box>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose} variant="ghost">
            Close
          </Button>
          <Button
            isLoading={isLoading}
            disabled={!isEULAChecked}
            onClick={handleSignAgreement}
          >
            {isEULASigned ? 'Already Signed' : 'Sign Agreement'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

EulaModal.propTypes = {
  selectedValues: PropTypes.array,
  setSelectedValues: PropTypes.func,
  history: PropTypes.object,
  isEULASigned: PropTypes.bool,
  setIsEULASigned: PropTypes.func,
  isEULAChecked: PropTypes.bool,
  setIsEULAChecked: PropTypes.func,
  shouldSendEULAEmail: PropTypes.bool,
  setShouldSendEULAEmail: PropTypes.func,
  agreement: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default EulaModal
