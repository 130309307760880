import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Link,
  OrderedList,
  ListItem,
  Heading,
  Text,
  Code,
  Flex,
} from '@chakra-ui/react'
import { PRODUCTS } from 'context/Programs/constants'
import { CONNECTION_METHODS } from '../constants'
import CopyButton from 'components/Shared/CopyButton'
import B from 'components/Shared/B'
import ProductImage from 'components/Shared/Product/ProductDetails'

const usernamePasswordInstructions = (
  <>
    <OrderedList spacing="16px" mb="16px">
      <ListItem>
        Let’s make sure those product account(s) are ready to go. It is
        recommended that you create a service account specifically for Reach
      </ListItem>
      <ListItem>
        Enter the user credentials, including your MFA secret key if applicable
      </ListItem>
      <ListItem>
        You’re done! We’ll verify authentication and let you know if we need
        anything else
      </ListItem>
    </OrderedList>
  </>
)

const azureOauthInstructions = (
  <>
    <Box>
      <Flex alignItems="center">
        <B>Hey, listen!</B>{' '}
        <ProductImage ml="8px" w="16px" product={PRODUCTS.microsoft} />
      </Flex>
      We recommend connecting this application to Reach if you use Azure Active
      do not use the Microsoft E5 and/or Protection Pack 2 Security Suite of
      products.
    </Box>
    <Box fontSize="24px" fontWeight="medium" my="16px">
      Azure Standalone Connection Instructions
    </Box>
    <OrderedList spacing="16px" mb="16px">
      <ListItem>
        Make sure that you or someone you can contact has the right
        administrative user with the permissions to add a new Enterprise app to
        Azure AD including Microsoft’s Graph API.
      </ListItem>
      <ListItem>
        Click the ‘Connect’ button to allow access yourself, or ‘Copy Link’ to
        send the link to your administrator. Then follow the steps to authorize
        our Reach app to connect to Azure AD.
      </ListItem>
      <ListItem>
        You’re done! You can review the Reach Security: Active Directory Reader
        permissions within Azure AD.
      </ListItem>
    </OrderedList>
  </>
)

const microsoftOauthInstructions = (
  <>
    <Box mb="24px">
      <Flex alignItems="center">
        <B>Hey, listen!</B>{' '}
        <ProductImage ml="8px" w="16px" product={PRODUCTS.microsoft} />
      </Flex>
      The connection will fail if you are not licensed for Microsoft E5 and/or
      Protection Pack 2 Security Suite.
    </Box>
    <OrderedList spacing="16px" mb="16px">
      <ListItem>
        Make sure that you or someone you can contact has the right
        administrative user with the permissions to add a new Enterprise app to
        connect to Microsoft’s Graph API.
      </ListItem>
      <ListItem>
        Click the ‘Connect’ button to allow access yourself, or ‘Copy Link’ to
        send the link to your administrator. Then follow the steps to authorize
        our Reach app to connect to the Graph API.
      </ListItem>
      <ListItem>
        If you would like Reach to automatically deploy configurations on your
        behalf, you’ll need to add the Security Reader role to the application.
        These details are covered in our documentation linked below.
      </ListItem>
      <ListItem>
        You’re done! You can review the Reach Security: MS Security Application
        permissions within Azure AD.
      </ListItem>
    </OrderedList>
  </>
)

const LDIF_COMMAND = `ldifde -l "c,cn,co,company,countryCode,department,directReports,displayName,distinguishedName,dn,givenName,groupType,instanceType,l,mail,manager,member,memberOf,msExchRecipientDisplayType,msExchRecipientTypeDetails,msExchResourceDisplay,msExchUserAccountControl,msExchVersion,name,objectClass,objectGUID,proxyAddresses,publicDelegates,publicDelegatesBL,sAMAccountType,sn,st,title,userAccountControl" -p Subtree -r "(|(objectClass=contact)(objectClass=group)(objectClass=msExchDynamicDistributionList)(objectClass=msExchSystemMailbox)(objectClass=msExchSystemObjectsContainer)(objectClass=organizationalPerson)(objectClass=organizationalUnit)(objectClass=person)(objectClass=user))" -f $Env:HOMEPATH\\Desktop\\export.ldif`
const fileSnapshotInstructions = (
  <>
    <OrderedList spacing="16px" mb="16px">
      <ListItem>
        Make sure that you have the right administrative user with the
        permissions to export metadata from Active Directory. Our instructions
        will use the a command line tool called Ldifde
      </ListItem>
      <ListItem>
        Run the the Ldifde command line tool passing it the arguments found in
        the following document{' '}
        <Link href="https://docs.reach.security/docs/connect/activeDirectory">
          MSFT Active Directory Export Steps
        </Link>
      </ListItem>
      <Box position="relative">
        <CopyButton
          size="xs"
          position="absolute"
          right="8px"
          top="8px"
          content={LDIF_COMMAND}
        />
        <Code
          borderRadius="8px"
          p="16px"
          w="100%"
          overflowX="auto"
          whiteSpace="pre"
        >
          {LDIF_COMMAND}
        </Code>
      </Box>

      <ListItem>Upload the Ldif file using the upload snapshot button</ListItem>
      <ListItem>You’re done!</ListItem>
    </OrderedList>
  </>
)

const clientSecretInstructions = (
  <>
    <OrderedList spacing="16px" mb="16px">
      <ListItem>Get your product accounts ready for Reach</ListItem>
      <ListItem>
        Retrieve the users Multi-Factor Authentication (MFA) secret key
      </ListItem>
      <ListItem>Import one or more MFA secret keys into Reach</ListItem>
      <ListItem>You’re done!</ListItem>
    </OrderedList>
  </>
)

const apiKeyInstructions = (
  <>
    <OrderedList spacing="16px" mb="16px">
      <ListItem>
        Let’s make sure your read only product account and API token are ready
        to go
      </ListItem>
      <ListItem>Enter the API token and hit submit</ListItem>
      <ListItem>
        You’re done! We’ll verify authentication and let you know if we need
        anything else
      </ListItem>
    </OrderedList>
  </>
)

const panosSetupInstructions = (
  <>
    <Heading size="md" mb="24px">
      Enable Configurations
    </Heading>
    <Text mb="24px">
      By enabling this integration, you’re telling Reach to produce recommended
      configurations. To receive those recommendations, click the button below.
    </Text>
  </>
)

const paloAltoPrismaInstructions = (
  <>
    <Heading size="md" mb="24px">
      Enable Configurations
    </Heading>
    <Text mb="24px">
      By enabling this integration, you’re telling Reach to produce recommended
      configurations. To receive those recommendations, click the button below.
    </Text>
  </>
)

const getProductInstructions = (product, authMethod) => {
  if (product === PRODUCTS.proofpointPps) {
    if (authMethod === CONNECTION_METHODS.USERNAME_PASSWORD)
      return usernamePasswordInstructions
  }
  if (product === PRODUCTS.proofpoint) {
    if (authMethod === CONNECTION_METHODS.USERNAME_PASSWORD)
      return usernamePasswordInstructions
  }
  if (product === PRODUCTS.activeDirectory) {
    if (
      authMethod === CONNECTION_METHODS.FILE_SNAPSHOT ||
      authMethod === CONNECTION_METHODS.DIRECT_UPLOAD
    )
      return fileSnapshotInstructions
  }
  if (product === PRODUCTS.azureActiveDirectory) {
    if (authMethod === CONNECTION_METHODS.USERNAME_PASSWORD)
      return usernamePasswordInstructions
    if (authMethod === CONNECTION_METHODS.OAUTH) return azureOauthInstructions
    if (authMethod === CONNECTION_METHODS.FILE_SNAPSHOT)
      return fileSnapshotInstructions
  }
  if (product === PRODUCTS.microsoft) {
    return microsoftOauthInstructions
  }
  if (product === PRODUCTS.crowdStrike) {
    if (authMethod === CONNECTION_METHODS.CLIENT_SECRET)
      return clientSecretInstructions
  }
  if (product === PRODUCTS.okta) {
    if (authMethod === CONNECTION_METHODS.API_KEY) return apiKeyInstructions
  }
  if (product === PRODUCTS.sentinelOne) {
    if (authMethod === CONNECTION_METHODS.API_KEY) return apiKeyInstructions
  }
  if (product === PRODUCTS.paloAlto) {
    return panosSetupInstructions
  }
  if (product === PRODUCTS.paloAltoPrisma) {
    return paloAltoPrismaInstructions
  }
  if (product === PRODUCTS.microsoft) {
    return usernamePasswordInstructions
  }
}

const documentationLinkMap = {
  [PRODUCTS.proofpointPps]:
    'https://docs.reach.security/docs/connect/quickstart', // TODO -- update with docs as they become available
  [PRODUCTS.proofpoint]: 'https://docs.reach.security/docs/connect/proofpoint',
  [PRODUCTS.azureActiveDirectory]:
    'https://docs.reach.security/docs/connect/azureAd',
  [PRODUCTS.microsoft]: 'https://docs.reach.security/docs/connect/azure_o365',
  [PRODUCTS.crowdStrike]: 'https://docs.reach.security/docs/connect/quickstart', // TODO -- update with docs as they become available
  [PRODUCTS.okta]: 'https://docs.reach.security/docs/connect/quickstart', // TODO -- update with docs as they become available
  [PRODUCTS.sentinelOne]:
    'https://docs.reach.security/docs/connect/sentinelOne',
}

export const ProductSetupDocumentationLink = ({
  product,
  icon,
  ...remaining
}) => {
  const href = documentationLinkMap[product]

  if (!href) return null

  return (
    <Link href={href} {...remaining}>
      Want more help? View the documentation {icon}
    </Link>
  )
}

ProductSetupDocumentationLink.propTypes = {
  product: PropTypes.string,
  authMethod: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.any,
}

export const ProductSetupInstructions = ({
  product,
  authMethod,
  ...remaining
}) => {
  const textBody = getProductInstructions(product, authMethod)

  if (!textBody) return null

  return (
    <Box ml="4px" {...remaining}>
      {textBody}
    </Box>
  )
}

ProductSetupInstructions.propTypes = {
  product: PropTypes.string,
  authMethod: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default ProductSetupInstructions
