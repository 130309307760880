import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
// import ReactGA from 'react-ga'

import '@fontsource/roboto/500.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/300.css'

import themeExtension from 'assets/themeExtension'
import {
  Center,
  Spinner,
  ChakraProvider,
  extendTheme,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { QueryClientProvider, QueryClient } from 'react-query'
import { AuthProvider, useAuth } from 'context/Auth'
import { ProgramsProvider } from 'context/Programs'
import { AppProvider } from 'context/App'
import { SecurityProfileProvider } from 'context/SecurityProfile'
import ErrorComponent from 'components/StatePage/ErrorPage'
import AxiosInterceptors from 'AxiosInterceptors'

// AUTHENTICATED VS. UNAUTHENTICATED APP SERVING
const AuthenticatedApp = React.lazy(() =>
  import(/* webpackChunkName: "AuthenticatedAppChunk" */ './AppAuthenticated')
)
const UnauthenticatedApp = React.lazy(() =>
  import(
    /* webpackChunkName: "UnauthenticatedAppChunk" */ './AppUnauthenticated'
  )
)

// GOOGLE ANALYTICS
// const TRACKING_ID = 'G-0FQ7QJDGBF'
// ReactGA.initialize(TRACKING_ID)

// CHAKRA THEMING
const theme = extendTheme(themeExtension)

// ROUTER
const history = createBrowserHistory()
// history.listen((location) => {
//   ReactGA.set({ page: location.pathname })
//   ReactGA.pageview(location.pathname)
// })

// REACT-QUERY
const queryClient = new QueryClient()

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = { pageError: false }
  }

  // app wide error catch, displays the "Oh no" page
  componentDidCatch(error, errorInfo) {
    // TODO: should be logging these errors
    this.setState({ pageError: { error, errorInfo } })
  }

  render() {
    const { pageError } = this.state

    return (
      <Router history={history}>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider queryClient={queryClient}>
              <AppProvider>
                <SecurityProfileProvider>
                  <ProgramsProvider>
                    {pageError ? (
                      <ErrorComponent
                        error={pageError.error}
                        errorInfo={pageError.errorInfo}
                      />
                    ) : (
                      <AppBody theme={theme} />
                    )}
                  </ProgramsProvider>
                </SecurityProfileProvider>
              </AppProvider>
            </AuthProvider>
          </QueryClientProvider>
        </ChakraProvider>
      </Router>
    )
  }
}

const AppBody = () => {
  const { isAuthenticated } = useAuth()
  let renderBody

  // uses lazy loading from above to serve the right part of the app
  if (isAuthenticated === true) {
    renderBody = <AuthenticatedApp />
  } else if (isAuthenticated === false) {
    renderBody = <UnauthenticatedApp />
  } else {
    // isAuthenticated is initialy null
    // so this else actually does trigger
    renderBody = (
      <Center
        data-testid="loading-app"
        w="100vw"
        h="100vh"
        bg={mode('gray.100', 'gray.900')}
      >
        <Spinner />
      </Center>
    )
  }

  return (
    <AxiosInterceptors>
      <Suspense
        fallback={
          <Center
            data-testid="loading-app"
            w="100vw"
            h="100vh"
            bg={mode('gray.100', 'gray.900')}
          >
            <Spinner />
          </Center>
        }
      >
        {renderBody}
      </Suspense>
    </AxiosInterceptors>
  )
}

AppBody.propTypes = {
  theme: PropTypes.object,
}
