import React from 'react'
import PropTypes from 'prop-types'
import {
  Popover,
  PopoverTrigger,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  ButtonGroup,
  Button,
  Text,
} from '@chakra-ui/react'

const DeleteUserPopover = ({ children, rowValues, onDeleteUser }) => {
  const initialFocusRef = React.useRef()

  return (
    <Popover initialFocusRef={initialFocusRef} placement="bottom">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          {rowValues.external_user
            ? 'Remove External User'
            : 'Permanently Delete User'}
        </PopoverHeader>
        <PopoverArrow />
        <PopoverBody>
          <Text>
            {rowValues.external_user
              ? 'Are you sure you want to revoke access to'
              : 'Are you sure you want to delete'}
          </Text>
          <Text display="inline" fontWeight="bold">
            {rowValues?.username}
          </Text>
          ?
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="flex-end"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button
              colorScheme="red"
              onClick={() => onDeleteUser({ username: rowValues.username })}
              data-testid={`confirm-delete-${rowValues.username}`}
            >
              {rowValues.external_user ? 'Revoke Access' : 'Delete User'}
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

DeleteUserPopover.propTypes = {
  children: PropTypes.any,
  rowValues: PropTypes.object,
  onDeleteUser: PropTypes.func,
}

export default DeleteUserPopover
