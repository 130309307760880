import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Button, Flex, Spinner, useToast } from '@chakra-ui/react'
import FormInput from 'components/Shared/Form/FormInput'
import SSOSetupConfirmation from './SSOSetupConfirmation'
import { useMutation } from 'react-query'
import { useApp } from 'context/App'
import { postSsoDetails } from 'context/Auth/api'
import { useAuth } from 'context/Auth'
import wait from 'waait'

const SetupSSOFormBroadcastUrl = ({ requestStatus }) => {
  const toast = useToast()
  const { handleError } = useApp()
  const { isSsoEnabled, ssoConfiguration, accessToken } = useAuth()

  const handlePostSSODetailsSuccess = async (response) => {
    const ssoMetadataUrl = response?.response?.redirect_url
    if (ssoMetadataUrl) {
      toast({
        title: 'SSO Details Found!',
        description:
          'You will now be redirected to your SSO provider to log in...',
        status: 'info',
        duration: 5000,
        position: 'top',
        isClosable: true,
      })
      await wait(1500)
      window.location.href = ssoMetadataUrl
    } else {
      toast({
        title: 'Error Establishing SSO!',
        description:
          'There was an issue getting the SSO redirect URI from what you provided, please double check and try again.',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      })
    }
  }

  const { mutate: postDetails, isLoading: isPostingSamlDetails } = useMutation(
    postSsoDetails,
    {
      onSuccess: handlePostSSODetailsSuccess,
      onError: handleError,
    }
  )

  const formSchema = yup.object().shape({
    sso_metadata_url: yup.string().required('Metadata URL is required'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
    defaultValues: {
      // can pass defaults from props
    },
  })

  const disabledStatus = isSsoEnabled

  const onSubmit = (values) => {
    postDetails({ values, accessToken })
  }

  const body = (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        // id="email-123" optional prop for id and label selection conflicts
        name="sso_metadata_url"
        label="Metadata URL"
        disabled={disabledStatus}
        register={register}
        errors={errors}
        defaultValue={ssoConfiguration?.sso_metadata_url}
      />
      {!isSsoEnabled && (
        <Flex mb="48px" justifyContent="flex-end">
          {isPostingSamlDetails ? (
            <Spinner />
          ) : (
            <SSOSetupConfirmation>
              <Button
                mr="8px"
                isLoading={requestStatus === 'loading'}
                loadingText="Submitting"
                disabled={disabledStatus}
                type="button"
              >
                Enable SSO
              </Button>
            </SSOSetupConfirmation>
          )}
        </Flex>
      )}
    </form>
  )

  return body
}

SetupSSOFormBroadcastUrl.defaultProps = {
  onClose: () => {},
}

SetupSSOFormBroadcastUrl.propTypes = {
  onClose: PropTypes.func,
  requestStatus: PropTypes.string,
}

export default SetupSSOFormBroadcastUrl
