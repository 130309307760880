import React from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import {
  Box,
  Flex,
  Button,
  useColorModeValue as mode,
  Icon,
} from '@chakra-ui/react'
import { AnimateSharedLayout } from 'framer-motion'
import ReachTooltip from './ReachTooltip'

const MotionBox = motion(Box)

const TextSwitch = ({
  options,
  value,
  onClick,
  size,
  disabled,
  flexDir,
  ...remaining
}) => {
  let iconSize = '24px'
  let padding = '8px'
  let buttonPadding = '4px 8px'
  let h = '24px'
  switch (size) {
    case 'sm':
      h = '28px'
      padding = '6px'
      buttonPadding = '4px 6px'
      iconSize = '16px'
      break
    case 'md':
      h = '32px'
      padding = '6px'
      buttonPadding = '4px 8px'
      break
    case 'lg':
      h = '40px'
      padding = '6px'
      buttonPadding = '8px 16px'
      break
  }
  return (
    <AnimateSharedLayout>
      <Flex
        p={padding}
        borderRadius="6px"
        bg={mode('gray.100', '#444a5e')}
        width="min-content"
        opacity={disabled ? 0.6 : 1}
        flexDir={flexDir}
        {...remaining}
      >
        {options.map((o, i) => {
          const isSelected = o.value === value
          const isDisabled = isSelected || disabled || o.disabled
          return (
            <Box
              mr={i < options.length - 1 && flexDir === 'row' ? '8px' : '0'}
              mb={i < options.length - 1 && flexDir === 'column' ? '8px' : '0'}
              position="relative"
              key={o?.name || o?.label}
            >
              <ReachTooltip shouldWrapChildren label={o.label}>
                <Button
                  isLoading={o.isLoading}
                  loadingText={o.loadingText}
                  cursor={isSelected ? 'default !important' : 'pointer'}
                  position="relative"
                  variant="ghost"
                  p={buttonPadding}
                  pl={o.icon ? '8px' : undefined}
                  h={h}
                  zIndex={3}
                  onClick={() => onClick(o?.value)}
                  disabled={isDisabled}
                  style={{ opacity: 1 }}
                  leftIcon={
                    o.icon ? (
                      <Icon w={iconSize} h={iconSize} as={o.icon} />
                    ) : undefined
                  }
                  color={
                    isSelected
                      ? mode('brand.light', 'brand.dark')
                      : isDisabled
                      ? mode('gray.600', 'gray.400')
                      : mode('gray.900', 'brand.light')
                  }
                  size={size}
                  spinner={o.spinner}
                  spinnerPlacement="end"
                >
                  {o?.name || o?.label}
                </Button>
              </ReachTooltip>
              {isSelected && (
                <MotionBox
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  position="absolute"
                  left="0"
                  right="0"
                  top="0"
                  bottom="0"
                  borderRadius="4px"
                  bg={mode('reachBlue.500', 'reachBlue.300')}
                  layoutId={`textSwitch-${options[0]?.value}`}
                  zIndex={0}
                />
              )}
            </Box>
          )
        })}
      </Flex>
    </AnimateSharedLayout>
  )
}

TextSwitch.defaultProps = { size: 'md', flexDir: 'row' }

TextSwitch.propTypes = {
  flexDir: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  onClick: PropTypes.func,
  size: PropTypes.string,
  disabled: PropTypes.bool,
}

export default TextSwitch
