import axios from 'axios'

export const getProgramList = () => {
  return axios.get(`/api/v1/programs/list`).catch((e) => Promise.reject(e))
}

export const getProgramGroup = (groupId) => {
  return axios
    .get(`/api/v1/programs?program_group=${groupId}`)
    .catch((e) => Promise.reject(e))
}

export const getProgramSummary = () =>
  axios.get('/api/v1/programs/summary').catch((e) => Promise.reject(e))

export const getProgramContentList = (groupId, programType) => {
  // testing variables
  // groupId = 'a991d482-c4ab-4da8-a9bf-f225030049de'
  // programType = 'baseline'

  return axios
    .get(
      `/api/v1/programs/content/list?program_group=${groupId}&program_type=${programType}`
    )
    .catch((e) => Promise.reject(e))
}

export const getProgramContent = (contentKey) => {
  return axios
    .get(`/api/v1/programs/content?content_key=${contentKey}`, {
      responseType: 'arraybuffer',
      responseEncoding: 'binary',
    })
    .catch((e) => Promise.reject(e))
}

export const postJobTrigger = ({ productName, programType, programGroupId }) =>
  axios
    .post('/api/v1/programs/deployment/job', {
      product_name: productName,
      program_type: programType,
      program_uuid: programGroupId,
    })
    .catch((e) => Promise.reject(e))

export const getJobDetails = (jobId) => {
  return axios
    .get('/api/v1/programs/deployment/job', { params: { job_uuid: jobId } })
    .catch((e) => Promise.reject(e))
}

export const getCanJobRollback = ({
  productName,
  programType,
  programGroupId,
}) => {
  return axios
    .get('/api/v1/programs/deployment/job/rollback', {
      params: {
        product_name: productName,
        program_type: programType,
        program_group: programGroupId,
      },
    })
    .catch((e) => Promise.reject(e))
}

export const postRollbackJob = (jobId) => {
  return axios
    .post('/api/v1/programs/deployment/job/rollback', {
      orig_deploy_job_uuid: jobId,
    })
    .catch((e) => Promise.reject(e))
}
