// useful constant for comparators
export const CONNECTION_METHODS = {
  USERNAME_PASSWORD: 'username_password',
  USERNAME_PASSWORD_HOSTNAME: 'username_password_hostname',
  OAUTH: 'oauth_app',
  CLIENT_SECRET: 'url_client_secret_client_id',
  API_KEY: 'api_key',
  FILE_SNAPSHOT: 'file_snapshot',
  DIRECT_UPLOAD: 'direct_upload',
  NONE: 'none',
}

// display names for auth methods
export const AUTH_METHODS_NAMES = {
  oauth_app: 'Allow Access',
  api_key: 'API Key',
  url_client_secret_client_id: 'Client Secret',
  username_password: 'Connect with Credentials',
  file_snapshot: 'Upload File Snapshot',
}

// determines the sorting order in the connectino drawer TextSwitch
export const AUTH_METHODS_PRIORITY = {
  oauth_app: 1,
  api_key: 0,
  url_client_secret_client_id: 0,
  username_password: 0,
  file_snapshot: -1,
}

// connection status used in the connection tiles
export const CONNECTION_STATUS = {
  HEALTHY: 'healthy',
  ERROR: 'error',
  NOT_CONNECTED: 'not-connected',
}
