import axios from 'axios'

/* ~~~~~~~~~~~~~~~~~~~~~~~ USER AGREEMENTS ~~~~~~~~~~~~~~~~~~~~~~~ */

// returns the latest user agreement version and text
export const getUserAgreements = () =>
  axios.get('/api/user-agreements').catch((e) => Promise.reject(e))

// returns an array of all the signed user agreements
export const getUserAgreementsList = () =>
  axios.get('/api/user-agreements/list').catch((e) => Promise.reject(e))

// signs a particular version
export const postSignUserAgreement = ({ version, sendEmail }) =>
  axios
    .post('/api/user-agreements/sign', {
      eula: { version },
      send_email: sendEmail,
    })
    .catch((e) => Promise.reject(e))

/* ~~~~~~~~~~~~~~~~~~~~~~~ CONNECTION MANAGEMENT ~~~~~~~~~~~~~~~~~~~~~~~ */

/**
 * This endpoint requires an access token with scope "standard" or
 * higher and returns a special token with scope "delegate" that can
 * only be used to register the app, making it safe to pass around in a URL.
 * @return {String} returns a delegate token
 */
export const getDelegateToken = () =>
  axios.get('/api/token/delegate').catch((e) => Promise.reject(e))

/**
 * Returns all application connection details for this tenant
 * @return {Array} array of all product connections
 */
export const getApplications = () =>
  axios.get('/api/application').catch((e) => Promise.reject(e))

/**
 * Returns the application connection details for this tenant
 * @param {string} id - Application UUID generated per tenant
 * @return {Object} returns a single connection
 */
export const getApplication = (appId) =>
  axios.get(`/api/application/${appId}`).catch((e) => Promise.reject(e))

/**
 * Returns the application connection details for this tenant
 * @return {Object} returns an object with keys mapping to products
 *                  containing the possible auth methods and fields
 */
export const getApplicationsAuthMethods = () =>
  axios
    .get('/api/products/supported_auth_methods')
    .catch((e) => Promise.reject(e))

/**
 * Sends credentials to connect a product to reach
 * If successful a health check will be populated
 * if unsuccessful health will not change and a detailed error returned
 * @param {string} product - ex: 'azure_ad' 'crowdstrike' 'okta' 'proofpoint_pps' 'proofpoint_tap' 'sentinelone',
 * @param {string} authMethod - refer to CONNECTION_METHODS in components/Shared/Connections/constants.js
 * @param {object} credentials - contains values that map to the authMethod
 */
// export const postConnectionCredentials = ({
//   product,
//   authMethod,
//   credentials,
// }) =>
//   axios
//     .post('/api/credentials/create', {
//       product,
//       auth_method: authMethod,
//       credentials,
//     })
//     .catch((e) => Promise.reject(e))

/* MOCKED VERSION FOR DEMO SITE */
/* THIS SHOULD ONLY EVER BE IN DEMO BRANCH */
export const postConnectionCredentials = async () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), 800)
  })

/**
 * Deletes all credentials for a given product
 * @param {String} id the uuid for an application (returned inside /api/application)
 */
export const deleteCredentials = ({ id }) =>
  axios
    .delete('/api/credentials/delete', { data: { application_uuid: id } })
    .catch((e) => Promise.reject(e))

/**
 * Sends credentials to connect a product to reach
 * If successful a health check will be populated
 * if unsuccessful health will not change and a detailed error returned
 * @param {object} body - data and such
 * @param {obejct} options - headers and such
 */
export const postDelegate = (body = {}, options = {}) =>
  axios
    .post('/api/credentials/delegate', body, options)
    .catch((e) => Promise.reject(e))

/**
 * generates data to upload a snapshot
 * @param {string} application_uuid - UUID for the application to upload to
 * @return {object} S3 data parameters used in the postUploadSnapshot function
 */
export const postSnapshotGenerate = (uuid) =>
  axios
    .post('/api/snapshot/upload-location/generate', {
      application_uuid: uuid,
    })
    .catch((e) => Promise.reject(e))

/**
 * uploads a snapshot to specified S3 data
 * @param {object} s3Data - contains a url, fields, and file to be uploaded
 * @return {object} S3 data parameters used in the postUploadSnapshot function
 */
// export const postUploadSnapshot = ({ url, fields, file }) => {
//   try {
//     const formData = new FormData()
//     Object.keys(fields).forEach((key) => formData.append(key, fields[key]))
//     formData.append('file', file)
//     return axios
//       .post(url, formData, { withCredentials: false })
//       .catch((e) => Promise.reject(e))
//   } catch (e) {
//     return new Error(e)
//   }
// }
export const postUploadSnapshot = async () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), 800)
  })

/**
 * confirm snapshot completion after postUploadSnapshot
 * @param {string} application_uuid - UUID for the application to upload to
 */
export const postSnapshotConfirm = (uuid) =>
  axios
    .post('/api/snapshot/confirm', {
      application_uuid: uuid,
    })
    .catch((e) => Promise.reject(e))

/* ~~~~~~~~~~~~~~~~~~~~~~~ CUSTOMER ENDPOINTS ~~~~~~~~~~~~~~~~~~~~~~~ */

/**
 * returns customer attributes, notably onboarding_status
 */
export const getCustomerAttributes = ({ accessToken } = {}) => {
  let options = {}
  if (accessToken) {
    options = { headers: { Authorization: `Bearer ${accessToken}` } }
  }
  return axios
    .get('/api/customer/attributes', options)
    .catch((e) => Promise.reject(e))
}

/**
 * returns all users for the tenant
 */
export const getUsers = () =>
  axios.get('/api/customer/users').catch((e) => Promise.reject(e))

/**
 * returns all users for the tenant
 * * @param {string} id - username of the user to be deleted
 */
export const deleteUser = ({ username }) =>
  axios
    .delete(`/api/customer/user`, { data: { email: username } })
    .catch((e) => Promise.reject(e))

/**
 * update a user role
 * * @param {string} username - username of the user to be updated
 * * @param {string} role - role to be udpated to
 */
export const editUserRole = ({ username, role }) =>
  axios
    .post('/api/customer/user/role', { username, role })
    .catch((e) => Promise.reject(e))

/**
 * returns all pending user invitations for the tenant
 */
export const getPendingInvitations = () =>
  axios.get('/api/customer/user/invitations').catch((e) => Promise.reject(e))

/**
 * deletes a pending invitation for the tenant given an email
 * * @param {string} email - email of the user invite to be deleted
 */
export const deletePendingInvitation = (body) => {
  return axios
    .delete(`/api/customer/user/invitations`, { data: body })
    .catch((e) => Promise.reject(e))
}
