import React from 'react'
import PropTypes from 'prop-types'
import {
  Popover,
  PopoverTrigger,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  ButtonGroup,
  Button,
  Text,
} from '@chakra-ui/react'

const DeletePendingInvitationPopover = ({
  children,
  pendingInviteDetails,
  onDeleteInvitation,
}) => {
  const initialFocusRef = React.useRef()

  return (
    <Popover initialFocusRef={initialFocusRef} placement="bottom">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Revoke User Invitation
        </PopoverHeader>
        <PopoverArrow />
        <PopoverBody>
          <Text>Are you sure you want to revoke your invitation to</Text>
          <Text display="inline" fontWeight="bold">
            {pendingInviteDetails?.email}
          </Text>
          ?
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="flex-end"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button
              colorScheme="red"
              onClick={() => {
                onDeleteInvitation({
                  invitation_email: pendingInviteDetails?.email,
                })
              }}
              data-testid={`confirm-delete-${pendingInviteDetails.email}`}
            >
              Revoke Invitation
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

DeletePendingInvitationPopover.propTypes = {
  children: PropTypes.any,
  pendingInviteDetails: PropTypes.object,
  onDeleteInvitation: PropTypes.func,
}

export default DeletePendingInvitationPopover
