import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  Center,
  Box,
  Grid,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import ConnectionTile from 'components/Shared/Connections/ConnectionTile'
import DisconnectConfirmationModal from 'components/Shared/Connections/DisconnectConfirmationModal'
import { getApplicationsAuthMethods } from 'context/App/api'
import { useQuery } from 'react-query'
import { useAuth } from 'context/Auth'
import { ROLES } from 'context/Auth/constants'
import { PRODUCTS } from 'context/Programs/constants'
import { AnimateSharedLayout } from 'framer-motion'
import Pulser from 'components/Shared/Pulser'

const ConnectionSettings = ({ onOpenEula }) => {
  const [disconnectProduct, setDisconnectProduct] = useState(null)
  const [rerenderKey, setRerenderKey] = useState(0)
  const [showMicrosoftPulse, setShowMicrosoftPulse] = useState(false)
  const { hasRole } = useAuth()
  const toast = useToast()

  // fetch all connection definitions
  // eslint-disable-next-line no-unused-vars
  const authMethodsQuery = useQuery(
    'checkAuthMethodsEula',
    getApplicationsAuthMethods,
    {
      retry: false,
      onError: (e) => {
        if (
          e.response.status === 403 &&
          e.response?.data?.detail !==
            'You do not have permission to perform this action.'
        ) {
          if (hasRole(ROLES.ADMIN)) {
            onOpenEula()
          } else {
            toast({
              title: 'EULA not signed!',
              description:
                'The user agreements have not been signed by an admin. Please find someone with the proper priviliege level to sign.',
              status: 'error',
              duration: null,
              isClosable: true,
              position: 'top',
            })
          }
        }
      },
    }
  )

  const gridGap = useBreakpointValue({
    base: '24px',
    lg: '48px',
  })

  const handleOpenDisconnect = (product) => {
    setDisconnectProduct(product)
  }

  const handleClose = () => setDisconnectProduct(null)
  const handleRerenderConnections = () => setRerenderKey(rerenderKey + 1)

  return (
    <>
      <DisconnectConfirmationModal
        onClose={handleClose}
        disconnectProduct={disconnectProduct}
        onDisconnectSuccess={handleRerenderConnections}
      />
      <Box mb="64px" data-testid="connection-settings-container">
        <Text mb="8px">
          This is where we can setup and manage connections between your
          security products and our platform. Select a supported product to get
          started.
        </Text>
        <Text mb="24px">
          You can learn more about the setup process{' '}
          <Link href="https://docs.reach.security/docs/connect/quickstart">
            here
          </Link>
          .
        </Text>
        <AnimateSharedLayout>
          <Center w="100%" px="3px">
            <Grid
              w="100%"
              gridGap={gridGap}
              gridTemplateColumns="repeat(auto-fit, minmax(260px, 1fr))"
              justifyContent="center"
              key={rerenderKey}
            >
              <Pulser
                isActive={showMicrosoftPulse}
                borderRadius="16px"
                tooltip="Connect to configure Microsoft modules"
                onMouseEnter={() => setShowMicrosoftPulse(false)}
              >
                <ConnectionTile
                  onOpenDisconnect={handleOpenDisconnect}
                  product={PRODUCTS.microsoft}
                />
              </Pulser>
              <ConnectionTile
                onOpenDisconnect={handleOpenDisconnect}
                product={PRODUCTS.azureActiveDirectory}
                setOnPulseParent={setShowMicrosoftPulse}
                requiredChild
              />
              <ConnectionTile
                noHealth
                onOpenDisconnect={handleOpenDisconnect}
                product={PRODUCTS.azureActiveDirectoryIdentityProtection}
                setOnPulseParent={setShowMicrosoftPulse}
              />
              <ConnectionTile
                noHealth
                onOpenDisconnect={handleOpenDisconnect}
                product={PRODUCTS.microsoftDefender}
                setOnPulseParent={setShowMicrosoftPulse}
              />
              <ConnectionTile
                onOpenDisconnect={handleOpenDisconnect}
                product="local_ad"
              />

              <ConnectionTile
                onOpenDisconnect={handleOpenDisconnect}
                product="proofpoint_pps"
              />
              <ConnectionTile
                onOpenDisconnect={handleOpenDisconnect}
                product="proofpoint_tap"
              />

              <ConnectionTile
                onOpenDisconnect={handleOpenDisconnect}
                product={PRODUCTS.paloAlto}
                noHealth
                noCredentialUpdate
              />
              <ConnectionTile
                onOpenDisconnect={handleOpenDisconnect}
                product="okta"
              />

              <ConnectionTile
                onOpenDisconnect={handleOpenDisconnect}
                product="sentinelone"
              />
              <ConnectionTile
                onOpenDisconnect={handleOpenDisconnect}
                product="crowdstrike"
              />
            </Grid>
          </Center>
        </AnimateSharedLayout>
      </Box>
    </>
  )
}

ConnectionSettings.propTypes = {
  onOpenEula: PropTypes.func,
}

export default ConnectionSettings
