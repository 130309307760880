import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalFooter,
  useToast,
} from '@chakra-ui/react'
import { AiOutlineDelete } from 'react-icons/ai'
import { deleteCredentials } from 'context/App/api'
import { useMutation } from 'react-query'
import { useApp } from 'context/App'

const DisconnectConfirmationModal = ({
  onClose,
  disconnectProduct,
  onDisconnectSuccess,
}) => {
  const toast = useToast()
  const { handleError } = useApp()
  const { mutate: onDeleteCredentials, isLoading } = useMutation(
    deleteCredentials,
    {
      onSuccess: () => {
        toast({
          title: disconnectProduct.name,
          description: 'Product Disconnected',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'top',
        })
        onDisconnectSuccess()
        onClose()
      },
      onError: handleError,
    }
  )

  const handleDeleteCredentials = () => {
    onDeleteCredentials({ id: disconnectProduct.uuid })
  }

  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={disconnectProduct}
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Disconnect {disconnectProduct?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>Are you sure you want to disconnect?</Box>
          <Box mt="8px">This operation cannot be undone.</Box>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            loadingText="Disconnecting..."
            mr="8px"
            colorScheme="red"
            leftIcon={<Icon as={AiOutlineDelete} />}
            onClick={handleDeleteCredentials}
            data-testid="confirm-disconnect-button"
          >
            Disconnect
          </Button>
          <Button disabled={isLoading} onClick={onClose} variant="ghost">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

DisconnectConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  disconnectProduct: PropTypes.object,
  setDisconnectProduct: PropTypes.func,
  onDisconnectSuccess: PropTypes.func,
}

export default DisconnectConfirmationModal
